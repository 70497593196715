@import "../abstracts/variables.scss";

.rounded_check {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;
  height: 4rem;
  width: 4rem;
  border: 0.6rem solid $whitecolor;

  svg {
    position: absolute;
    left: 0.7rem;
    top: -0.3rem;
    height: 3rem;
    width: 3.1rem;
  }

  &--margin {
    margin-right: 2rem;
  }

  &--big {
    height: 6rem;
    width: 6rem;
    border: 0.8rem solid $whitecolor;

    svg {
      height: 4rem;
      width: 5.1rem;
    }
  }
}
