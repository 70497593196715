@import "../abstracts/variables.scss";

.card {
  border-radius: 0.5rem;
  overflow: hidden;

  &__top {
    height: 20rem;
    width: 20rem;
    position: relative;
    overflow: hidden;
    background: $darkgraycolor;
  }

  &__image {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &__bottom {
    background: #291c2c;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.7rem;
  }

  &__title {
    text-transform: uppercase;
    font-weight: 300;
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }

  &__list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-items: center;
    margin-bottom: 2rem;
  }

  &__item {
    display: flex;
    white-space: nowrap;
  }

  &__label {
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: 500;
    text-transform: uppercase;
    color: $darkpurplecolor;
  }

  &__info {
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: $whitecolor;
    margin-right: 1.2rem;
    width: 2.3rem;
    text-align: right;
  }
}
