@import "../../abstracts/variables.scss";

.select_tabs {
  display: flex;
  align-items: center;
  background: $darkgraycolor;
  border-radius: 1rem;
  padding: 0.5rem;
  width: fit-content;

  &__item {
    padding: 0.7rem 1.5rem;
    border-radius: 0.7rem;
    text-transform: uppercase;
    font-weight: 500;
    cursor: pointer;
    transition: 0.1s ease background;
    display: inline-flex;
    align-items: center;

    svg {
      margin-right: 0.5rem;
    }

    &:not(:last-child) {
      margin-right: 0.5rem;
    }

    &:hover {
      background: rgba(#eb4a41, 0.3);
    }

    &--active {
      background: $orangecolor !important;

      svg path {
        fill: $whitecolor;
      }
    }
  }
}
