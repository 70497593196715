@import "../abstracts/variables.scss";

.game_button {
  font-size: 2.4rem;
  font-weight: 300;
  text-transform: uppercase;
  color: $whitecolor;
  border-radius: 10rem;
  background-image: linear-gradient(180deg, #ff7854 0%, #e73b4b 100%);
  overflow: hidden;
  padding: 0.2rem;
  position: relative;
  transition: 0.1s ease transform;
  user-select: none;
  display: block;
  width: 100%;
  max-width: 20rem;
  text-align: center;
  cursor: pointer;

  @media (max-width: $md3) {
    font-size: 3rem;
    max-width: 24rem;
  }

  $self: &;

  &:active {
    transform: scale(0.97);
  }

  &--small {
    font-size: 2rem;

    @media (max-width: $md3) {
      font-size: 2.6rem;
    }

    span {
      padding: 0.4rem 1.6rem !important;
    }
  }

  &--disabled {
    background: gray;
    cursor: default;

    #{$self}__bar {
      display: none;
    }

    &:active {
      transform: scale(1);
    }
  }

  span {
    border-radius: 10rem;
    padding: 0.8rem 2.4rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: #16081b;

    @media (max-width: $md3) {
      padding: 0.8rem 3rem;
    }
  }

  &__top {
    top: 0;
    left: -3.5rem;
    animation-name: t-move;
  }

  &__bottom {
    bottom: 0;
    left: -12.5rem;
    animation-name: b-move;
  }

  &__bar {
    position: absolute;
    width: 5rem;
    height: 0.2rem;
    background: rgba(#ffd796, 1);
    transition: all 7s linear;
    animation-duration: 7s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    animation-delay: 3s;
  }
}

@keyframes t-move {
  0% {
    left: -3.5rem;
  }

  15% {
    left: 20rem;
  }

  55% {
    left: 20rem;
  }

  70% {
    left: -3.5rem;
  }
}

@keyframes b-move {
  0% {
    left: -12.5rem;
  }

  15% {
    left: 20rem;
  }

  55% {
    left: 20rem;
  }

  70% {
    left: -12.5rem;
  }
}
