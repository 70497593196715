@import "../abstracts/variables.scss";

.auth {
  width: 100%;
  padding: 12.4rem 0;
  position: relative;

  @media (max-width: $md2) {
    padding: 6.2rem 0;
  }

  @media (max-width: $md3) {
    padding: 0;
    background: #16081b;
    min-height: 100vh;
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 0;

    @media (max-width: $md3) {
      display: none;
    }
  }

  &__content {
    margin: 0 auto;
    width: 67rem;
    height: 68.2rem;
    padding-top: 12.7rem;
    padding-bottom: 9.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;

    @media (max-width: $md3) {
      padding: 4rem 0;
      justify-content: flex-start;
      height: 100%;
      width: 100%;
    }
  }

  &__frame {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    height: 100%;
    width: 100%;

    svg {
      height: 100%;
      width: 100%;
    }
  }

  &__wrapper {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 40rem;
    width: 100%;

    @media (max-width: $md4) {
      padding: 0 2rem;
    }
  }

  &__logo {
    margin-bottom: 3rem;
  }

  &__text {
    font-size: 1.4rem;
    line-height: 2.6rem;
    color: $darkpurplecolor;
    margin-bottom: 2rem;
    text-transform: uppercase;

    @media (max-width: $md3) {
      font-size: 2.4rem;
    }

    span {
      color: $whitecolor;
    }
  }

  &__message {
    font-size: 1.4rem;
    line-height: 2.6rem;
    text-transform: uppercase;
    color: $darkpurplecolor;
    text-align: left;
    margin-bottom: 1.2rem;
  }

  &__title {
    font-family: "Grotesk";
    font-weight: 300;
    color: $whitecolor;
    font-size: 3rem;
    line-height: 4rem;
    margin-bottom: 1rem;
    text-transform: uppercase;
  }

  &__button {
    font-size: 1.4rem;
    text-transform: uppercase;
    color: $orangecolor;
    font-weight: 300;
    margin-bottom: 3rem;
    line-height: 2.6rem;
  }

  &__form {
    margin-bottom: 5.7rem;
    width: 100%;

    button {
      margin: 0 auto;
      margin-top: 2.5rem;
    }
  }

  &__link {
    font-size: 1.8rem;
    text-transform: uppercase;
    color: $whitecolor;

    @media (max-width: $md3) {
      font-size: 2.4rem;
      line-height: 3.2rem;
    }

    &--small {
      font-size: 1.4rem;
      line-height: 2.8rem;
      text-align: left;
      color: $darkpurplecolor;
      text-align: center;
      max-width: 35rem;
      margin: 0 auto;
      margin-top: 3rem;
      margin-bottom: 0 !important;
    }

    span {
      display: inline-block;
      margin-right: 0.5rem;
    }

    a {
      color: $orangecolor;
    }

    button {
      font-size: 1.8rem;
      text-transform: uppercase;
      font-weight: 300;
      color: $orangecolor;
    }

    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  &__message {
  }

  &__checkbox {
    position: absolute;
    right: -4rem;
    top: 50%;
    transform: translateY(-50%);
  }

  &__promocode {
    display: flex;
    position: relative;
    width: 90%;

    button {
      position: absolute;
      right: -9rem;
      top: 50%;
      transform: translateY(-70%);
      height: 3rem;
      margin-top: 0;

      @media (max-width: $md3) {
        right: -14rem;
      }
    }
  }

  &__error {
    color: $orangecolor;
    text-align: left;
    width: 100%;
    display: block;
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 500;
  }

  &__steps {
    position: relative;
    justify-content: space-between;
    align-items: center;
    display: flex;
    max-width: 40rem;
    width: 100%;
    margin-bottom: 2.6rem;
  }

  &__step {
    height: 3.5rem;
    width: 3.5rem;
    border-radius: 50%;
    border: 0.3rem solid $darkgraycolor;
    background: $darkgraycolor;
    color: $whitecolor;
    font-size: 1.8rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;

    @media (max-width: $md3) {
      height: 4.5rem;
      width: 4.5rem;
      font-size: 2.6rem;
    }

    &:not(:last-child) {
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateX(-50%);
        background: #332736;
        height: 0.2rem;
        max-width: 9.2rem;
        width: 100vw;
        left: 7.8rem;
      }
    }

    svg {
      width: 1.95rem;
      height: 1.55rem;
    }

    &--active {
      border: 0.3rem solid $orangecolor;
    }

    &--passed {
      background: $orangecolor;
      border: 0.3rem solid $orangecolor;

      &::after {
        background: $orangecolor !important;
      }
    }
  }

  &__go_back {
    position: absolute;
    top: -1rem;
    right: -1rem;
    z-index: 3;
    cursor: pointer;
    transition: 0.1s ease transform;

    @media (max-width: $md3) {
      top: 3rem;
      right: 3rem;
    }

    &:hover {
      transform: scale(1.05);
    }

    &:active {
      transform: scale(0.95);
    }
  }

  &__warning {
    color: $orangecolor;
    border-radius: 2rem;
    text-transform: uppercase;
    font-size: 2rem;
    line-height: 3rem;
    margin-bottom: 4rem;
    display: flex;
    align-items: center;

    svg {
      fill: $orangecolor;
      height: 4rem;
      width: 4rem;
      margin-right: 1rem;
    }
  }
}
