@import "../../abstracts/variables.scss";

.join_race {
  position: relative;
  height: 101vh;
  width: 100%;

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  &__content {
    position: relative;
    z-index: 2;
    width: 100%;
    color: $whitecolor;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 12.5rem;
    grid-row-gap: 0.3rem;
    padding: 3.5rem 4rem;
    flex: 0 1 50%;
  }

  &__wrapper {
    background: rgba(14, 6, 16, 0.5);
    flex: 1 1 100%;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
  }

  &__no_cars {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 4rem;
      line-height: 3.5rem;
      text-transform: uppercase;
      font-family: "Grotesk";
      margin-bottom: 2.5rem;
    }
  }
}
