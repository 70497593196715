@import "../abstracts/variables.scss";

.primary_button {
  border-radius: 10rem;
  text-transform: uppercase;
  padding: 0.2rem;
  background-image: linear-gradient(180deg, #ff7854 0%, #e73b4b 100%);
  font-size: 1.4rem;
  line-height: 1.6rem;
  max-width: 10rem;
  color: $whitecolor;
  position: relative;
  bottom: -0.1rem;
  letter-spacing: 0.07rem;

  @media (max-width: $md3) {
    font-size: 2rem;
    line-height: 2.2rem;
    max-width: 20rem;
  }

  &:hover {
    span {
      background: transparent;
    }
  }

  span {
    background: $darkcolor;
    border-radius: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 0.7rem 2rem;
    white-space: nowrap;
    transition: 0.1s ease background;

    @media (max-width: $md3) {
      padding: 0.75rem 3rem;
    }
  }

  &--disabled {
    cursor: default;
    background: $graycolor;

    &:hover {
      span {
        background: $darkcolor;
      }
    }
  }

  &--less-padding {
    span {
      padding: 0.7rem 1rem;

      @media (max-width: $md3) {
        padding: 0.7rem 3rem;
      }
    }
  }

  &--big {
    max-width: 20rem;
    width: 100%;
    display: block;
    margin: 0 auto;

    @media (max-width: $md3) {
      max-width: 30rem;
    }
  }
}
