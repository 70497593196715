@import "../abstracts/variables.scss";

.error {
  height: 100%;
  width: 100%;
  position: relative;
  min-height: 100vh;

  &__bg {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__content {
    position: absolute;
    z-index: 2;
    margin: 0 auto;
    height: 100%;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__block {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 55rem;
    max-height: 70rem;
    height: 100%;
    width: 100%;
  }

  &__frame {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    svg {
      height: 100%;
      width: 100%;
    }
  }

  &__logo {
    position: relative;
    z-index: 3;
    margin-bottom: 2rem;
  }

  &__text {
    position: relative;
    z-index: 3;
    color: $whitecolor;
    font-size: 2.4rem;
    line-height: 3.2rem;
    text-align: center;
  }
}
