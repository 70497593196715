@import "../../styles/abstracts/variables.scss";

.input {
  $self: &;
  background: radial-gradient(
    22.2% 138.79% at 22.2% 51.67%,
    rgba(38, 115, 115, 0.6) 0%,
    rgba(0, 0, 0, 0.6) 100%
  );

  padding: 1.2rem;
  font-size: 1.8rem;
  line-height: 2rem;
  width: 100%;
  border-radius: 0.4rem;
  border: 0.1rem solid transparent;
  color: $defaultwhitecolor;
  transition: 0.1s ease border, 0.3s ease box-shadow;
  backdrop-filter: blur(10px);

  &:hover {
    border-color: rgba($orangecolor, 0.5);
  }

  &:focus {
    border-color: $orangecolor;
    box-shadow: 0px 0px 40px 0px $orangecolor;
  }

  &__wrapper {
    width: 100%;

    &.filled {
      #{$self} {
        box-shadow: 0px 0px 40px 0px $orangecolor;
        border-color: $orangecolor;
      }
    }
  }

  &__container {
    width: 100%;
    position: relative;
  }
}
