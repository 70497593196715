@import "../../../styles/abstracts/variables.scss";

.hero {
  max-height: 88rem;
  position: relative;
  max-width: 100%;
  overflow: hidden;

  @media (max-width: $md2) {
    max-height: 102.4rem;
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__separator {
    position: absolute;
    z-index: 2;
    bottom: -2rem;
    width: 100%;

    @media (max-width: $md1) {
      bottom: -1rem;
    }
  }

  &__content {
    position: relative;
    z-index: 2;
    padding-top: 26.5rem;
    padding-bottom: 22.8rem;

    @media (max-width: $md2) {
      padding-left: 8rem;
      padding-top: 20rem;
      padding-bottom: 30rem;
    }

    @media (max-width: $md3) {
      padding-top: 14rem;
      padding-bottom: 22rem;
      padding-left: 0;
    }
  }

  &__title {
    font-family: Hacked;
    font-size: 12.6rem;
    line-height: 13rem;
    max-width: 53.2rem;
    margin-bottom: 2rem;

    @media (max-width: $md1) {
      font-size: 10rem;
      line-height: 10rem;
    }

    @media (max-width: $md2) {
      font-size: 14.4rem;
      line-height: 14.8rem;
      margin-bottom: 2.5rem;
      max-width: 61rem;
    }

    @media (max-width: $md3) {
      font-size: 7.4rem;
      line-height: 8rem;
      margin-bottom: -2rem;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    max-width: 61rem;
    margin-bottom: 3rem;

    @media (max-width: $md2) {
      flex-direction: column;
      max-width: 38rem;
    }
  }

  &__button {
    max-width: 21rem;
    margin-left: 2rem;

    @media (max-width: $md2) {
      max-width: unset;
      margin-top: 3rem;
      margin-left: 0;
      max-height: 5rem;
    }
  }

  &__label {
    color: $graycolor;
    font-size: 1.3rem;
    font-weight: 500;
    line-height: 1.8rem;

    @media (max-width: $md2) {
      max-width: 24.6rem;
    }
  }

  &__car {
    max-width: 81rem;
    position: absolute;
    right: 0;
    width: 100%;
    bottom: 0;

    @media (max-width: $md2) {
      bottom: -5%;
      z-index: 2;
      right: -10%;
    }
  }

  &__features {
    position: absolute;
    right: 23%;
    top: 30rem;

    @media (max-width: $md1) {
      right: 15%;
      top: 25rem;
    }

    @media (max-width: $md2) {
      max-width: 20.5rem;
      right: 10%;
      top: 12rem;
    }

    @media (max-width: $md3) {
      position: relative;
      right: unset;
      top: unset;
      display: flex;
      flex-direction: column-reverse;
      max-width: unset;
      margin-bottom: 3rem;
    }

    svg {
      width: 100%;

      path {
        @media (max-width: $md2) {
          stroke-width: 3;
        }
      }
    }
  }

  &__frame {
  }

  &__updates {
    display: flex;
    padding-left: 2.5rem;

    @media (max-width: $md2) {
      flex-direction: column;
    }

    @media (max-width: $md3) {
      padding-left: 0;
      flex-direction: row;
      background: radial-gradient(
        49.61% 198.27% at 43.73% 50%,
        rgba(38, 115, 115, 0.6) 0%,
        rgba(0, 0, 0, 0.6) 100%
      );
      box-shadow: 0px 0px 8px 0px #33e9e9c4;
      box-shadow: 0px 0px 6px 0px #33e9e940 inset;
      border-radius: 30rem;
      border: 0.5px solid #33e9e9;
      backdrop-filter: blur(2px);
      padding: 1.2rem 1.5rem;
      justify-content: space-between;
    }
  }

  &__bar {
    @media (max-width: $md3) {
      width: 0.05rem;
      height: 2rem;
      background: #33e9e9;
    }
  }

  &__update {
    font-size: 1.8rem;
    line-height: 2.4rem;
    padding: 1rem 1.6rem;
    min-width: 17rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: radial-gradient(
      49.61% 198.27% at 43.73% 50%,
      rgba(38, 115, 115, 0.6) 0%,
      rgba(0, 0, 0, 0.6) 100%
    );
    box-shadow: 0px 0px 8px 0px #33e9e9c4;
    box-shadow: 0px 0px 6px 0px #33e9e940 inset;
    border-radius: 30rem;
    border: 0.5px solid #33e9e9;
    backdrop-filter: blur(2px);

    &:not(:last-child) {
      margin-right: 2rem;

      @media (max-width: $md2) {
        margin-right: 0;
        margin-bottom: 2rem;
      }

      @media (max-width: $md3) {
        margin-bottom: 0;
      }
    }

    @media (max-width: $md3) {
      padding: 0;
      min-width: unset;
      font-size: 1.3rem;
      line-height: 1.8rem;
      background: 0;
      border: 0;
      box-shadow: none;
      border-radius: 0;
      backdrop-filter: none;
    }
  }

  &__round {
    border: 2px solid #33e9e9;
    height: 0.9rem;
    width: 0.9rem;
    border-radius: 50%;
    position: absolute;
    bottom: 0.5rem;
    left: -0.2rem;

    @media (max-width: $md2) {
      bottom: 1.5rem;
      left: -0.6rem;
    }

    @media (max-width: $md3) {
      left: unset;
      bottom: unset;
      right: -0.5rem;
      top: 0.5rem;
    }
  }
}
