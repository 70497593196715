@import "../../abstracts/variables.scss";

.checkbox {
  display: inline-flex;
  cursor: pointer;

  $self: &;

  span {
    text-transform: uppercase;
    font-size: 1.6rem;
    padding-left: 1rem;
    margin-right: 5rem;
    user-select: none;
  }

  &__common {
    color: $commoncolor;
  }

  &__uncommon {
    color: $uncommoncolor;
  }

  &__rare {
    color: $rarecolor;
  }

  &__epic {
    color: $epiccolor;
  }

  &__legendary {
    color: $legendarycolor;
  }

  &:hover {
    #{$self}__check {
      transform: scale(1.2);
    }
  }

  &__input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
  }

  &--checked {
    #{$self}__label {
      svg {
        display: block;
      }
    }
  }

  &__label {
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  &__check {
    border-radius: 50%;
    border: 0.3rem solid $whitecolor;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 2.5rem;
    width: 2.5rem;
    position: relative;
    cursor: pointer;
    transition: 0.3s ease transform;

    svg {
      height: 2rem;
      width: 3rem;
      top: 33%;
      left: 80%;
      transform: translate(-50%, -50%);
      position: absolute;
      display: none;
    }
  }
}
