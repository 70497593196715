@import "../abstracts/variables.scss";

.inventory_card {
  $self: &;
  height: 30rem;
  max-height: 30rem;
  max-width: 17rem;
  position: relative;
  list-style: none;

  &--open {
    height: unset;

    #{$self}__characteristics {
      bottom: 0.7rem;
    }

    #{$self}__bottom {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
    }
  }

  &--common {
    #{$self}__bottom {
      background: $commongradient;
    }
  }

  &--uncommon {
    #{$self}__bottom {
      background: $uncommongradient;
    }
  }

  &--rare {
    #{$self}__bottom {
      background: $raregradient;
    }
  }

  &--epic {
    #{$self}__bottom {
      background: $epicgradient;
    }
  }

  &--legendary {
    #{$self}__bottom {
      background: $legendarygradient;
    }
  }

  &--box {
    #{$self}__name {
      margin-bottom: 4rem;
      transform: translateY(3rem);
    }

    label {
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        width: 100%;
      }
    }
  }

  &__top {
    position: relative;
    height: 50%;
    background: $whitecolor;
    min-height: 15rem;
  }

  &__img {
    height: 100%;
    width: 100%;
  }

  &__bottom {
    height: 50%;
    min-height: 13rem;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
  }

  &__car,
  &__rarity {
    color: #2a1a2e;
    font-size: 2rem;
    line-height: 2.8rem;
    text-transform: uppercase;
    font-weight: 500;
  }

  &__rarity {
    margin-bottom: 1rem;
  }

  &__name {
    text-transform: uppercase;
    color: $whitecolor;
    font-size: 2.8rem;
    font-weight: 300;
  }

  &__characteristics {
    position: absolute;
    display: flex;
    align-items: center;
    bottom: 0.7rem;
    left: 1rem;
    width: 85%;
    justify-content: space-between;
  }

  &__attribute {
    font-size: 2rem;
    display: flex;
    align-items: center;

    svg {
      margin-right: 0.5rem;
      height: 2.6rem;
      width: 2.6rem;
    }
  }

  &__equip {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(#000, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    div {
      text-transform: uppercase;
      margin-top: 1rem;
      font-size: 2.4rem;
    }
  }
}
