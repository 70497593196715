@import "../../abstracts/variables.scss";

.info {
  display: flex;
  align-items: center;
  padding: 4rem 9rem;
  padding-top: 1rem;
  border-bottom: 0.1rem solid $darkpurplecolor;
  height: 25.8rem;

  $self: &;

  &--qualification {
    height: 37.4rem;
    align-items: flex-end;

    #{$self}__secondary {
      min-height: unset;
    }

    #{$self}__main {
      margin-right: 7.2rem;
    }

    #{$self}__name {
      line-height: 4rem;
    }

    #{$self}__prize {
      margin-bottom: 3rem;
    }

    #{$self}__racers,
    #{$self}__starts {
      display: flex;
      font-size: 3.7rem;
      margin-bottom: 0;

      span {
        margin-right: 1rem;
        font-size: inherit;
      }
    }

    #{$self}__racers {
      margin-bottom: 2rem;
    }

    #{$self}__buttons {
      flex-direction: column;
      align-items: flex-end;

      a {
        margin-right: 0;
        margin-bottom: 2rem;
      }
    }
  }

  &__text {
    font-family: "Grotesk";
    font-size: 2.6rem;
    line-height: 3rem;
    text-transform: uppercase;
    font-weight: 300;
    max-width: 61.5rem;
    margin-right: auto;
  }

  &__main {
    margin-right: 7.2rem;
    flex: 0 1 30%;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 3.4rem;
  }

  &__icon {
    margin-right: 3.2rem;
    width: 9.5rem;
    height: 10.5rem;

    perspective: 10rem;
    transition: transform 7s linear;
    animation-duration: 7s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    animation-name: iconAnimation;
    transform: rotate3d(0, 1, 0, -25deg);

    svg {
      height: 100%;
      width: 100%;
    }
  }

  &__name {
    text-transform: uppercase;
    font-size: 3.3rem;
    margin-bottom: 1rem;
    font-family: "Grotesk";
    white-space: nowrap;
  }

  &__number {
    font-size: 1.6rem;
    text-transform: uppercase;
  }

  &__prize {
    margin-bottom: 2rem;
  }

  &__prize,
  &__cost {
    text-transform: uppercase;
    font-size: 3rem;
    font-family: "Grotesk";

    span {
      text-transform: lowercase;
    }
  }

  &__secondary {
    font-size: 2.8rem;
    text-transform: uppercase;
    min-height: 19.6rem;

    &:not(:last-child) {
      margin-right: 3rem;
    }
  }

  &__racers {
    margin-bottom: 4rem;

    div {
      font-size: 2rem;

      &:not(:last-child) {
        margin-bottom: 0.4rem;
      }

      span {
        font-size: 2rem;
        display: inline-block;
      }
    }
  }

  &__racers,
  &__starts {
    font-family: "Grotesk";

    span {
      margin-bottom: 1rem;
      font-size: 3rem;
      display: block;
    }
  }

  &__navigation {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: 1 1 30%;
    align-items: flex-end;

    &--qual {
      height: 100%;
      justify-content: flex-start;
    }
  }

  &__buttons {
    display: flex;
    margin-bottom: 3rem;
    width: 100%;
    justify-content: flex-end;

    button:first-child {
      margin-right: 3rem;
    }
  }

  &__total {
    text-transform: uppercase;
    font-size: 2.8rem;
    text-align: center;
    margin-right: 5rem;

    span {
      color: $darkpurplecolor;
      display: block;
      font-size: 1.6rem;
      line-height: 2.8rem;
    }
  }

  &__free_text {
    text-transform: uppercase;
    font-size: 2rem;
    max-width: 40rem;
    text-align: right;
    font-weight: 500;
  }
}

@keyframes iconAnimation {
  0% {
    transform: rotate3d(0, 1, 0, -25deg);
  }

  50% {
    transform: rotate3d(0, 1, 0, 25deg);
  }

  100% {
    transform: rotate3d(0, 1, 0, -25deg);
  }
}
