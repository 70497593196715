@import "../abstracts/variables.scss";

.small_main_card {
  height: 11.2rem;
  width: 100%;
  position: relative;
  color: $whitecolor;
  margin-bottom: 2rem;
  cursor: pointer;

  $self: &;

  &:hover {
    #{$self}__img {
      transform: scale(1.13);
    }

    button {
      span {
        background: transparent;
      }
    }
  }

  &:active {
    #{$self}__img {
      transform: scale(1);
    }
  }

  &--history {
    #{$self}__icon {
      svg {
        height: 6rem;
        width: 6rem;
      }
    }

    #{$self}__title {
      margin-bottom: 0.5rem;
    }

    #{$self}__img {
      left: -2rem;
      top: -3rem;
    }
  }

  &__frame {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__content {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    z-index: 1;
    overflow: hidden;
    padding: 1.5rem;
    padding-left: 3.5rem;
  }

  &__img {
    position: absolute;
    left: -3rem;
    top: -3rem;
    z-index: 1;
    transition: 0.8s ease transform;

    &--fullsize {
      left: 0 !important;
      top: 0 !important;
      width: 100%;
      height: 100%;
    }
  }

  &__wrapper {
    position: relative;
    z-index: 2;
    width: 75%;
  }

  &__title {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 2rem;
    margin-bottom: 1.5rem;

    &--less-margin {
      margin-bottom: 0.5rem;
    }
  }

  &__text {
    color: $darkpurplecolor;
    font-size: 1.6rem;
    line-height: 1.6rem;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 2rem;
  }

  &__icon {
    position: relative;
    z-index: 2;
    margin-right: 2rem;
    perspective: 10rem;
    transition: transform 7s linear;
    animation-duration: 7s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    animation-name: iconAnimation;
    transform: rotate3d(0, 1, 0, -25deg);

    svg {
      height: 5rem;
      width: 5rem;
    }
  }

  &__reward {
    font-family: "Grotesk";
    font-size: 4rem;
    font-weight: 300;
    display: flex;
    margin-bottom: 1rem;
    text-transform: uppercase;
    justify-content: space-between;
    width: 100%;

    div > span {
      font-size: 2.6rem;
      display: inline-block;
      margin-right: 2rem;
    }
  }

  &__races_count {
    color: $darkpurplecolor;
    font-size: 1.6rem;
    line-height: 1.6rem;
    text-transform: uppercase;
    font-weight: 500;
  }

  &__prize {
    font-weight: 700;
    font-size: 4rem;
    text-transform: uppercase;
    font-family: "Grotesk";
  }

  &__prize_subtext {
    font-family: "Grotesk";
    text-transform: uppercase;
    font-size: 2.4rem;
    font-weight: 300;
    position: relative;
    top: 1.5rem;
  }
}

@keyframes iconAnimation {
  0% {
    transform: rotate3d(0, 1, 0, -25deg);
  }

  50% {
    transform: rotate3d(0, 1, 0, 25deg);
  }

  100% {
    transform: rotate3d(0, 1, 0, -25deg);
  }
}
