@import "../abstracts/variables.scss";

.main_card {
  $self: &;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  height: 100%;
  z-index: 0;

  &:hover {
    #{$self}__img {
      transform: scale(1.13);
    }
  }

  &:active {
    #{$self}__img {
      transform: scale(1);
    }
  }

  &--race {
    height: 78.1rem;

    #{$self}__icon {
      margin-top: 14rem;
      margin-bottom: 1.5rem !important;
    }

    #{$self}__top_row {
      padding: 4rem 0;
      padding-left: 4rem;
      padding-right: 3rem;
    }

    #{$self}__title {
      margin-bottom: 2rem;
    }

    button {
      position: absolute !important;
      top: 55%;
    }
  }

  &--legal {
    #{$self}__content {
      padding-bottom: 3.8rem;
    }

    #{$self}__text {
      max-width: 38.2rem;
    }
  }

  &--street {
    #{$self}__icon {
      margin: 4rem 0 !important;
      margin-bottom: 2rem !important;
    }

    #{$self}__race_name {
      margin-bottom: 1rem;
    }
  }

  &--coming-soon {
    cursor: default;

    #{$self}__frame {
      svg path {
        fill: #0d0c0e;
      }
    }

    #{$self}__content {
      justify-content: center;
    }

    #{$self}__icon {
      margin-bottom: 2rem;
      margin-top: 0;
    }

    #{$self}__soon {
      margin-bottom: 2rem;
    }

    #{$self}__img {
      filter: saturate(0.1);
    }

    &:hover {
      #{$self}__img {
        transform: scale(1);
      }
    }
  }

  &--small {
    height: 35rem;

    &:not(:last-child) {
      margin-bottom: 2rem;
    }

    #{$self}__icon {
      margin: 5rem 0;
      margin-bottom: 3rem;
    }

    #{$self}__text {
      position: relative;
      z-index: 3;
    }
  }

  &--special {
    #{$self}__top_row {
      padding-top: 10rem;
    }
  }

  &--long-street {
    height: 74rem;
  }

  &__lights {
    position: absolute;
    top: 0;
  }

  &__row {
    display: flex;
    align-items: center;

    &--right {
      text-align: right;
      div:first-child {
        margin-right: 1rem;
      }
    }

    svg {
      margin-right: 1rem;
    }
  }

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
  }

  &__frame {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 0;
  }

  &__content {
    position: relative;
    z-index: 0;
    overflow: hidden;
    color: $whitecolor;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    transform: translateZ(-1000px);
  }

  &__live {
    text-transform: uppercase;
    background: $orangecolor;
    border-radius: 1.5rem;
    padding: 0.4rem 1rem;
    width: 8rem;
  }

  &__top_row {
    padding: 4rem 0;
    padding-left: 2rem;
    padding-right: 5rem;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    z-index: 3;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    div:nth-child(2) {
      text-align: right;
    }
  }

  &__top_second_row {
    padding: 4rem 0;
    padding-left: 4rem;
    padding-right: 3rem;
    padding-top: 10rem;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    z-index: 3;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  &__label {
    font-size: 1.4rem;
    text-transform: uppercase;
  }

  &__info {
    font-size: 2.2rem;
    display: block;
    font-family: "Grotesk";
    font-weight: 300;
    text-transform: uppercase;
  }

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: 0.8s ease transform;
    width: 100%;
    transform: translateZ(-1000px);
  }

  &__icon {
    position: relative;
    margin: 7rem 0;
    margin-top: 13rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    margin-bottom: 5.5rem;
    perspective: 10rem;
    transition: transform 7s linear;
    animation-duration: 7s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    animation-name: iconAnimation;
    transform: rotate3d(0, 1, 0, -25deg) translateZ(1px);

    &--race {
      margin-bottom: 2rem;
    }
  }

  &__soon_label {
    position: absolute;
    text-transform: uppercase;
    top: 6rem;
    right: -0.5rem;
    transform: rotate(45deg);
    font-size: 2rem;
    font-weight: 500;
    color: $orangecolor;
    z-index: 2;
    width: 12rem;
    text-align: center;

    &::before {
      content: "";
      position: absolute;
      background: $whitecolor;
      height: 3rem;
      right: -3rem;
      top: -0.4rem;
      width: 160%;
      z-index: -1;
    }
  }

  &__title {
    font-size: 3.2rem;
    position: relative;
    z-index: 3;
    text-transform: uppercase;
    margin-bottom: 3rem;
    font-family: "Grotesk";
    font-weight: 300;
  }

  &__race_name {
    position: relative;
    z-index: 3;
    font-size: 1.6rem;
    margin-bottom: 2rem;
    text-transform: uppercase;
  }

  &__text {
    font-size: 1.6rem;
    line-height: 2.8rem;
    color: $darkpurplecolor;
    margin-bottom: 1.3rem;
    max-width: 23.2rem;
    text-align: center;
    text-transform: uppercase;
    position: relative;
    z-index: 3;

    @media (max-width: $md3) {
      font-size: 2rem;
    }
  }

  &__reward {
    font-size: 5rem;
    line-height: 5rem;
    margin-bottom: 1rem;
    font-family: "Grotesk";
    font-weight: 300;
    position: relative;
    z-index: 2;
  }

  &__participants {
  }

  &__soon {
    text-transform: uppercase;
    font-size: 4rem;
    line-height: 4rem;
    font-family: "Grotesk";
    font-weight: 300;
    position: relative;
    z-index: 2;
  }

  &__race_info {
    position: absolute;
    z-index: 2;
    bottom: 3.7rem;
    display: flex;
    width: 80%;
    justify-content: space-between;
  }

  &__racers,
  &__chance_points {
    display: flex;
    align-items: center;
  }

  &__info_icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 2.8rem;
    height: 3.8rem;
    width: 3.8rem;
    margin-right: 1.2rem;

    &--chance {
      width: 4.6rem;
    }

    svg path {
      fill: $whitecolor;
    }

    span {
      position: absolute;
      font-weight: 500;
      transform: translateY(-0.1rem);
    }
  }

  &__info_text {
    font-family: "Grotesk";
    font-size: 2.6rem;
    text-transform: uppercase;

    &--legal {
      font-weight: 300;
      text-align: left !important;
      font-size: 2.2rem;

      span {
        font-family: $fontfamily;
        font-size: 1.4rem;
      }
    }

    span {
      font-size: 1.6rem;
      display: block;
    }
  }

  button {
    position: relative;
    z-index: 4;
  }
}

@keyframes iconAnimation {
  0% {
    transform: rotate3d(0, 1, 0, -25deg) translateZ(1px);
  }

  50% {
    transform: rotate3d(0, 1, 0, 25deg) translateZ(1px);
  }

  100% {
    transform: rotate3d(0, 1, 0, -25deg) translateZ(1px);
  }
}

@keyframes border-1-anim {
  0% {
    opacity: 1;
  }

  24% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

  26% {
    opacity: 1;
  }

  98% {
    opacity: 1;
  }

  99% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes border-2-anim {
  0% {
    opacity: 1;
  }

  24% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

  26% {
    opacity: 0;
  }

  27% {
    opacity: 1;
  }

  97% {
    opacity: 1;
  }

  98% {
    opacity: 0;
  }

  99% {
    opacity: 1;
  }
}

@keyframes border-3-anim {
  0% {
    opacity: 1;
  }

  26% {
    opacity: 1;
  }

  27% {
    opacity: 0;
  }

  28% {
    opacity: 1;
  }

  96% {
    opacity: 1;
  }

  97% {
    opacity: 0;
  }

  98% {
    opacity: 1;
  }
}

@keyframes border-4-anim {
  0% {
    opacity: 1;
  }

  27% {
    opacity: 1;
  }

  28% {
    opacity: 0;
  }

  29% {
    opacity: 1;
  }

  42% {
    opacity: 1;
  }

  43% {
    opacity: 0;
  }

  44% {
    opacity: 1;
  }

  95% {
    opacity: 1;
  }

  96% {
    opacity: 0;
  }

  97% {
    opacity: 1;
  }
}

@keyframes image-2-anim {
  0% {
    opacity: 1;
  }

  32% {
    opacity: 1;
  }

  33% {
    opacity: 0;
  }

  34% {
    opacity: 1;
  }

  79% {
    opacity: 1;
  }

  80% {
    opacity: 0;
  }

  81% {
    opacity: 1;
  }
}

@keyframes image-3-anim {
  0% {
    opacity: 1;
  }

  54% {
    opacity: 1;
  }

  55% {
    opacity: 0;
  }

  56% {
    opacity: 1;
  }

  81% {
    opacity: 1;
  }

  82% {
    opacity: 0;
  }

  83% {
    opacity: 1;
  }
}

@keyframes image-4-anim {
  0% {
    opacity: 1;
  }

  18% {
    opacity: 1;
  }

  19% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  80% {
    opacity: 1;
  }

  81% {
    opacity: 0;
  }

  82% {
    opacity: 1;
  }
}

.border {
  &-1,
  &-2,
  &-3,
  &-4 {
    transition: none;
    opacity: 1;
    animation-duration: 13s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
  }

  &-1 {
    animation-name: border-1-anim;
  }

  &-2 {
    animation-name: border-2-anim;
    opacity: 0;
  }

  &-3 {
    animation-name: border-3-anim;
  }

  &-4 {
    animation-name: border-4-anim;
  }
}

.image {
  &-2,
  &-3,
  &-4 {
    transition: none;
    opacity: 1;
    animation-duration: 13s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
  }

  &-2 {
    animation-name: image-2-anim;
  }

  &-3 {
    animation-name: image-3-anim;
  }

  &-4 {
    animation-name: image-3-anim;
  }
}
