@import "../../abstracts/variables.scss";

.receive_bnb {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 41rem;

  &__title {
    font-size: 3rem;
    text-transform: uppercase;
    margin-bottom: 3.5rem;
    color: $whitecolor;
    font-family: "Grotesk";
  }

  &__qr {
    height: 22.6rem;
    width: 22.6rem;
    margin-bottom: 3rem;
    background: $whitecolor;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__qr_content {
    height: 90%;
    width: 90%;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__text {
    line-height: 2.8rem;
    font-size: 1.6rem;
    color: $darkpurplecolor;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 3rem;
  }

  &__address {
    width: 100%;
    font-size: 1.8rem;
    font-weight: 300;
    margin-bottom: 1rem;
    color: $whitecolor;
    display: flex;
    align-items: center;

    button {
      margin-left: 1.5rem;
    }

    svg {
      margin-right: 0;
    }
  }

  &__copy {
    margin-bottom: 2rem;
  }
}
