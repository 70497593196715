@import "../abstracts/variables.scss";

.copy {
  color: $orangecolor;
  font-size: 1.4rem;
  line-height: 2.8rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4rem;
  height: 3.2rem;

  &--no-margin {
    margin-bottom: 0;
  }

  &--input {
    position: absolute;
    top: 50%;
    right: 0;
  }

  svg {
    margin-right: 1.5rem;
    transition: 0.1s ease transform;
  }

  &:hover {
    svg {
      transform: scale(1.15);
    }
  }

  &:active {
    svg {
      transform: scale(0.9);
    }
  }
}
