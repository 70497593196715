// Colors //
$defaultwhitecolor: #ffffff;
$defaultblackcolor: #000000;

$darkcolor: #111115;
$darktextcolor: #3f404f;
$orangecolor: #f35850;
$graycolor: #d6d6d6;
$darkbluecolor: #1c1d27;

$orangegradient: linear-gradient(270deg, #e73b4b 0%, #ff7854 20%, #e73b4b 100%);

// Breakpoints //

$minwidth: 320px;
$mw: 1240;

$md0: 1440px;
$md1: 1279.98px;
$md2: 1023.98px;
$md3: 767.98px;
$md4: 479.98px;
