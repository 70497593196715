@import "../abstracts/variables.scss";

.burger-wrapper {
  display: none;
  padding: 1.5rem;
  position: fixed;
  background: rgba($whitecolor, 0.25);
  top: 2rem;
  left: 1rem;
  z-index: 11;
  border-radius: 0.5rem;
  box-shadow: 0.1rem 0.1rem 1rem rgba(#000, 0.25);

  &--active {
    background: $whitecolor;
  }

  @media (max-width: $md3) {
    display: block;
  }
}

.burger {
  display: none;

  @media (max-width: $md3) {
    width: 3.7rem;
    display: block;
    position: relative;
    height: 3rem;
    z-index: 11;

    span {
      content: "";
      width: 100%;
      height: 0.4rem;
      background: $orangecolor;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      transition: 0.3s;
    }

    &:before,
    &:after {
      content: "";
      transition: 0.3s;
      width: 100%;
      height: 0.4rem;
      background: $orangecolor;
      position: absolute;
      left: 0;
    }

    &:before {
      top: 0;
    }

    &:after {
      bottom: 0;
    }

    &--active {
      &::before {
        transform: rotate(45deg);
        top: 1.3rem;
      }

      & span {
        transform: scale(0);
      }

      &::after {
        transform: rotate(-45deg);
        bottom: 1.3rem;
      }
    }
  }
}
