@import "../../abstracts/variables.scss";

.select {
  max-width: 20rem;
  width: 100%;
  position: relative;
  height: 3.8rem;
  z-index: 4;

  $self: &;

  &:not(:last-child) {
    margin-right: 3rem;
  }

  &--active {
    #{$self}__wrapper {
      span {
        border-radius: 2rem 2rem 0 0;
      }
    }

    #{$self}__list {
      display: block;
    }
  }

  &__wrapper {
    cursor: pointer;
    top: 0;
    position: absolute;
    border-radius: 2rem;
    text-transform: uppercase;
    padding: 0.2rem;
    background-image: linear-gradient(180deg, #ff7854 0%, #e73b4b 100%);
    font-size: 1.6rem;
    color: $whitecolor;
    width: 100%;

    cursor: pointer;

    span {
      background: $darkcolor;
      border-radius: 10rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      width: 100%;
      padding: 0.7rem 2rem;
      white-space: nowrap;
    }
  }

  &__header {
    user-select: none;
    display: flex;
    justify-content: space-between;
  }

  &__list {
    border-radius: 0 0 2rem 2rem;
    background: $darkcolor;
    display: none;
    width: 100%;
    overflow: hidden;
    padding-top: 1rem;
  }

  &__item {
    user-select: none;
    padding: 0.5rem 2rem;
    width: 100%;

    &:hover {
      background: #544558;
    }
  }
}
