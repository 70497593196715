.Toastify {
  &__toast {
    overflow: visible;
    box-shadow: 0px 0.4rem 0.4rem 0px #00000040;
    border-radius: 0.4rem;
    font-size: 2rem;
    line-height: 2.4rem;
    text-transform: uppercase;
    color: $whitecolor;
    font-weight: 300;
    font-family: $fontfamily;
    width: 31rem;
    margin-left: auto;
    padding: 1rem;
    min-height: unset;

    &-body {
      padding: 0.6rem;
    }

    &--error {
      background: linear-gradient(
        270deg,
        #ff7854 0%,
        #e73b4b 101.13%
      ) !important;
    }

    &--success {
      background: linear-gradient(270deg, #42cedf 0%, #168cb8 101.13%);
    }

    &-container--top-right {
      top: 3em;
      right: 3em;
    }
  }

  &__progress-bar {
    background: $whitecolor !important;
  }

  &__close-button,
  &__toast-icon {
    svg {
      fill: $whitecolor !important;
    }
  }

  &__close-button {
    min-height: 3rem;
    min-width: 3rem;
    width: 3rem;
    position: relative;
    top: -4rem;
    left: 4rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    svg {
      height: 2.6rem;
      width: 2.6rem;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -0.1rem;
      height: 100%;
      width: 100%;
      border-radius: 50%;
      border: 0.2rem solid $orangecolor;
    }
  }

  &__toast-icon {
    width: 4rem;

    svg {
      width: 4rem !important;
      height: 5rem !important;
    }
  }
}
