@import "../../abstracts/variables.scss";

.form {
  &__control {
    width: 100%;
    position: relative;

    &:not(:last-child) {
      margin-bottom: 1.2rem;
    }

    ::placeholder {
      color: $darkpurplecolor;
    }
  }

  &__wrapper {
    width: 100%;
  }

  &__label {
    color: $darkpurplecolor;
    font-size: 1.4rem;
    line-height: 2.8rem;
    text-transform: uppercase;
    margin-bottom: 0.7rem;

    @media (max-width: $md3) {
      font-size: 2rem;
    }
  }
}
