@import "../abstracts/variables.scss";

.notification {
  background: $darkcolor;
  position: absolute;
  right: 2rem;
  bottom: 2rem;
  z-index: 5;
  width: 100%;
  max-width: 40rem;
  padding: 0.8rem;
  padding-right: 7rem;
  cursor: pointer;
  clip-path: polygon(50% 0%, 100% 0, 100% 100%, 75% 100%, 7% 100%, 0 53%, 7% 0);
  transform: translateX(100%);
  opacity: 0;
  visibility: hidden;
  transition: 0.7s ease-in-out all;

  &--active {
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
  }

  &__content {
    display: flex;
    align-items: center;
  }

  &__image {
    margin-right: 5rem;
    height: 10rem;
  }

  &__message {
    color: $darkpurplecolor;
    text-transform: uppercase;
    font-size: 1.6rem;

    span {
      color: $whitecolor;
    }
  }
}
