@import "../abstracts/variables.scss";

.racing {
  position: relative;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  color: $whitecolor;
  flex: 1 1 100%;

  &__frame {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    iframe {
      height: 100%;
      width: 100%;
    }
  }

  // Old styles

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 75%;
    height: 100%;
    z-index: 1;
  }

  &__content {
    position: relative;
    display: flex;
    z-index: 3;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  &__left {
    flex: 0 1 77%;
    position: relative;
  }

  &__map {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    top: -4rem;
    left: -34rem;

    svg {
      height: 121%;
      width: 160%;
    }
  }

  &__right {
    flex: 0 1 25%;
    background: $darkcolor;
    min-height: 100vh;
    height: 100%;
    width: 100%;
    padding: 3rem;
    padding-left: 5rem;
    padding-top: 4rem;
    box-shadow: 3.7rem 0px 11.7rem 18.7rem rgba(24, 15, 26, 1);
    position: relative;
    z-index: 2;

    & > button {
      width: 100%;
      max-width: unset;
    }
  }

  &__starts {
    font-weight: 500;
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;

    & > span {
      color: $darkpurplecolor;
      text-transform: uppercase;
      margin-right: 1rem;
      font-weight: 500;
    }

    button {
      margin-left: 2rem;
    }
  }

  &__name {
    font-size: 5rem;
    line-height: 6rem;
    font-family: "Grotesk";
    text-transform: uppercase;
    margin-bottom: 0.5rem;
  }

  &__prize {
    margin-bottom: 3rem;

    span {
      color: $darkpurplecolor;
      font-size: 1.4rem;
      font-weight: 500;
      text-transform: uppercase;
    }

    div {
      font-family: "Grotesk";
      font-size: 3.6rem;
      line-height: 4rem;
    }
  }

  &__list_wrapper {
    height: 100vh;
    overflow-y: auto;
    max-height: 65vh;
    overflow-x: hidden;
    width: 120%;
    left: -13%;
    position: relative;
    margin-bottom: 2rem;
  }

  &__list {
  }

  &__item {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 1.6rem;
    font-weight: 600;
    padding: 0.2rem 0.5rem;
    border-radius: 0.5rem;
    position: relative;

    &--active {
      background: rgba($graycolor, 0.5);

      &::before {
        content: "";
        background: $whitecolor;
        border-radius: 50%;
        height: 1rem;
        width: 1rem;
        position: absolute;
        left: 1.5rem;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &--orange {
      color: $orangecolor;

      &::before {
        content: "";
        background: $orangecolor;
        border-radius: 50%;
        height: 1rem;
        width: 1rem;
        position: absolute;
        left: 1.5rem;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &--lose {
      color: $graycolor;
    }

    &:not(:last-child) {
      margin-bottom: 0.1rem;
    }

    span {
      &:first-child {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex: 0 1 15%;
        padding-right: 2rem;
        margin-left: 8%;
      }

      &:last-child {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex: 0 1 40%;
      }

      &:nth-child(2) {
        flex: 0 1 65%;
        align-items: flex-start;
        justify-content: flex-start;
      }
    }

    &--header {
      color: $darkpurplecolor;
      font-size: 1.4rem;

      span {
        &:first-child {
          justify-content: flex-start;
          align-items: flex-start;
        }

        &:last-child {
          padding-right: 1.5rem;
        }
      }
    }
  }
}
