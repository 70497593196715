@import "../../styles/abstracts/variables.scss";

.header {
  top: 5rem;
  background: #232325cc;
  border-radius: 1rem;
  justify-content: space-between;
  display: flex;
  align-items: center;
  width: 96%;
  padding: 2rem;
  position: absolute;
  z-index: 3;

  @media (max-width: $md1) {
    width: 100%;
    top: 0;
    left: 0;
    border-radius: 0;
  }

  li {
    text-transform: uppercase;

    &:hover {
      color: $orangecolor;
    }
  }
}
