@import "../../../styles/abstracts/variables.scss";

.roadmap {
  padding-top: 8rem;
  padding-bottom: 27rem;
  background: $darkbluecolor;
  position: relative;
  max-width: 144rem;
  overflow: hidden;

  @media (max-width: $md2) {
    padding-bottom: 10rem;
    padding-top: 4rem;
  }

  $self: &;

  :global(.swiper) {
    padding-right: 20rem;
  }

  :global(.swiper-wrapper) {
    transition-duration: 0.5s !important;
  }

  :global(.swiper-slide) {
    &:first-child {
      z-index: 10;
    }
  }

  :global(.landing-container) {
    @media (max-width: $md3) {
      padding: 0;
    }
  }

  &__separator {
    position: absolute;
    bottom: -6rem;
    width: 100%;
    z-index: 2;

    @media (max-width: $md1) {
      bottom: -4rem;
    }

    @media (max-width: $md1) {
      bottom: -3rem;
    }

    @media (max-width: $md3) {
      bottom: -2rem;
    }
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  &__line {
    display: flex;
    position: relative;
    z-index: 2;
  }

  &__trigger {
    // opacity: 0;
    // visibility: hidden;
    position: relative;
    z-index: 2;
  }

  &__item {
    min-width: 24rem;
    min-height: 34.3rem;
    position: relative;
    padding-top: 5rem;
    user-select: none;

    &:first-child {
      margin-left: 10rem;
      z-index: 10;
    }

    &:last-child {
      margin-right: 50rem;
    }

    &.active {
      #{$self}__round {
        background: #2fa725;
      }
    }

    &:not(:last-child) {
      margin-right: 10.7rem;
    }
  }

  &__content {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      right: 0;
      height: 100%;
      width: 20rem;
      top: 0;
      background: rgb(28, 29, 39);
      background: linear-gradient(
        90deg,
        rgba(28, 29, 39, 0) 0%,
        rgba(28, 29, 39, 1) 100%
      );
      z-index: 2;

      @media (max-width: $md3) {
        display: none;
      }
    }
  }

  &__date {
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 2.4rem;
    position: absolute;
    top: 0;
  }

  &__round {
    background: #f35850;
    border-radius: 50%;
    border: 4px solid #1c1d27;
    height: 2rem;
    width: 2rem;
  }

  &__road {
    background-image: url("../../../../../public/img/landing/roadmap/road.svg");
    background-repeat: repeat-x;
    width: 100%;
    height: 3rem;
    top: 5rem;
    position: absolute;
  }

  &__car {
    position: absolute;
    top: 2.5rem;
    z-index: 3;
    height: 7rem;
    width: 7rem;
    transition: 1s ease all;
  }

  &__list {
    position: absolute;
    transform: translateY(3rem);
    color: #868f97;
    font-size: 1.4rem;
    line-height: 2.1rem;
    font-weight: 500;
    list-style-type: disc;

    li {
      list-style-type: disc;
      list-style-position: inside;
    }
  }

  &__finish {
    position: absolute;
    right: 30%;
    transform: translateY(-20%);

    @media (max-width: $md3) {
      right: 0;
    }
  }

  &__text {
    color: #3f404f;
    font-family: Hacked;
    font-size: 3.7rem;
    line-height: 4.3rem;
    position: absolute;

    &.start {
      left: -35%;
      top: 3rem;
      transform: rotate(-90deg);
    }

    &.finish {
      transform: translateY(-20%);
      right: -3rem;

      @media (max-width: $md3) {
        right: -10rem;
      }
    }
  }
}
