.spine-player-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 1000;
}

.spine-player-container > div {
  position: relative;
  height: 40%;
  width: 40%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.done {
  opacity: 0;
  pointer-events: none;
  -webkit-transition: opacity 0.8s ease;
  -o-transition: opacity 0.8s ease;
  transition: opacity 0.8s ease;
}
