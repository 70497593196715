@import "../../../styles/abstracts/variables.scss";

.app {
  padding-top: 8rem;
  padding-bottom: 10.2rem;
  position: relative;
  background: $darkcolor;
  overflow: hidden;

  @media (max-width: $md2) {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  &__top_separator {
    position: absolute;
    top: -1rem;
    z-index: 2;
    width: 100%;
  }

  &__row {
    display: flex;
    align-items: center;

    @media (max-width: $md2) {
      margin-top: -4rem;
    }

    @media (max-width: $md3) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  &__img {
    margin-right: 8rem;
    position: relative;
    z-index: 2;

    @media (max-width: $md2) {
      margin-right: 3rem;
    }

    @media (max-width: $md3) {
      margin-right: 0;
      margin-bottom: 3rem;
    }
  }

  &__separator {
    position: absolute;
    bottom: -1rem;
    width: 100%;
    z-index: 2;
  }

  &__top_separator {
    position: absolute;
    top: -1rem;
    z-index: 2;
    width: 100%;
  }

  &__title {
    font-size: 2.8rem;
    line-height: 3.8rem;
    margin-bottom: 2rem;
    font-weight: 600;

    @media (max-width: $md2) {
      font-size: 2.4rem;
      line-height: 3.2rem;
    }
  }

  &__text {
    font-weight: 500;
    color: #868f97;
    margin-bottom: 4.4rem;

    @media (max-width: $md2) {
      font-size: 1.3rem;
      line-height: 2rem;
      margin-bottom: 2rem;
    }
  }

  &__button {
    @media (max-width: $md2) {
      display: block;
      text-align: center;
    }
  }

  &__content {
    @media (max-width: $md2) {
      max-width: 33.5rem;
    }
  }

  &__pluses {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
