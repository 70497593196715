@import "../abstracts/variables.scss";

.shop {
  position: relative;
  height: 100%;
  width: 100%;
  padding: 5rem 4.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 100%;

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    user-select: none;
  }

  &__wrapper {
    width: 100%;
  }

  &__promocode {
    position: relative;
    z-index: 2;
    margin-bottom: 7rem;
    display: flex;
    color: $whitecolor;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    text-transform: uppercase;
    font-weight: 600;

    button {
      max-width: 13rem;
    }

    p {
      margin-right: 1rem;
    }
  }

  &__grid {
    width: 100%;
    position: relative;
    z-index: 2;
    display: flex;
    gap: 1.2rem;
    flex-direction: column;
  }
}
