@import "../../abstracts/variables.scss";

.history {
  &__filters {
    padding: 2.5rem 5.5rem;
    padding-top: 0;
    display: flex;
    align-items: center;
    color: #614c65;
    font-size: 2.4rem;
    text-transform: uppercase;
    border-bottom: 0.1rem solid $darkpurplecolor;
    position: relative;
    z-index: 5;
  }

  &__filter {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.1s ease transform;

    &--up {
      svg {
        transform: rotate(180deg);
      }
    }

    &--select {
      width: 100%;
      max-width: 20rem;
    }

    svg {
      margin-left: 1rem;
      height: 1.6rem;
      width: 1.4rem;
      transition: 0.1s ease transform;
    }

    &:not(:last-child) {
      margin-right: 5rem;
    }

    &:first-child {
      margin-right: 6rem;
    }
  }

  &__wrapper {
    background: rgba(14, 6, 16, 0.5);
    height: 80vh;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 80vh;
  }

  &__list {
    display: flex;
    flex-direction: column;
    padding: 3rem 4.5rem;
  }

  &__no_races {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 4rem;
    font-family: "Grotesk";
    text-transform: uppercase;
  }
}
