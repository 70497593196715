@import "../../abstracts/variables.scss";

.item {
  display: flex;
  align-items: center;
  height: 10rem;
  position: relative;
  cursor: pointer;
  overflow: hidden;

  $self: &;

  &:hover {
    #{$self}__wrapper {
      background: #433846;

      &::before {
        background: #433846;
      }
    }
  }

  &--active {
    #{$self}__wrapper {
      background: $darkgraycolor;

      &::before {
        background: $darkgraycolor;
      }
    }
  }

  &--disabled {
    pointer-events: none;
    cursor: default;
  }

  &--in-race {
    pointer-events: none;
    cursor: default;

    #{$self}__wrapper {
      padding-right: 6.1rem;
    }

    &:hover {
      #{$self}__wrapper {
        background: $darkcolor;

        &::before {
          background: $darkcolor;
        }
      }
    }
  }

  &--qual {
    cursor: default !important;
    pointer-events: all !important;

    &:hover {
      #{$self}__wrapper {
        background: $darkcolor;

        &::before {
          background: $darkcolor;
        }
      }
    }

    #{$self}__name {
      margin-bottom: 2.5rem;
    }

    #{$self}__wrapper {
      justify-content: space-between;
    }
  }

  &--qualification {
    #{$self}__chars {
      margin-right: auto;
    }

    #{$self}__in_race {
      top: 2rem;
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    background: $darkcolor;
    height: 100%;
    padding: 1rem 2rem;
    border-radius: 0 0.4rem 0.4rem 0;
    width: 100%;
    font-weight: 500;
    justify-content: space-between;

    &::before {
      content: "";
      background: $darkcolor;
      width: 15%;
      position: absolute;
      left: 4rem;
      top: 0;
      height: 100%;
    }
  }

  &__info {
    font-size: 2rem;
    text-transform: uppercase;
    border-right: 0.1rem solid $darkpurplecolor;
    max-width: 13.5rem;
    padding-right: 3rem;
    margin-right: 1.7rem;
    position: relative;
  }

  &__name {
    margin-bottom: 1.5rem;
    font-size: 2rem;
  }

  &__level {
  }

  &__chars {
    margin-right: 1.7rem;
  }

  &__durability,
  &__acceleration,
  &__handling {
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    text-transform: uppercase;

    svg {
      height: 2rem;
      width: 2rem;
      margin-right: 1rem;
    }
  }

  &__durability {
    margin-bottom: 0.3rem;
  }

  &__acceleration {
    margin-bottom: 0.3rem;
  }

  &__handling {
  }

  &__total {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    margin-right: 3.5rem;

    span {
      white-space: nowrap;
      color: $darkpurplecolor;
      font-size: 1.2rem;
      text-transform: uppercase;
    }
  }

  &__checkbox {
    margin-top: 1rem;
    user-select: none;
    pointer-events: none;
  }

  &__in_race {
    background: $greencolor;
    font-size: 2.4rem;
    height: 6rem;
    padding: 2rem 1rem;
    border-radius: 0.5rem;
    position: absolute;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transform: rotate(-90deg);
    text-transform: uppercase;
    right: -2rem;

    &--owner {
      background: $whitecolor;
      color: $darkcolor;
      width: 10rem;
    }
  }

  &__qual {
    font-size: 2.4rem;
  }

  &__qual_info {
    text-transform: uppercase;
    position: relative;
  }

  &__qual_btn {
    max-width: 20.5rem;
  }
}
