html,
body {
  font-family: $fontfamily, Arial;
  font-size: 0.52vw;
  background: $blackcolor;
  font-weight: 300;
}

a {
  color: currentColor;
}

p,
span,
h1,
h2,
h3,
h4,
h5,
h6,
ul {
  margin: 0;
  font-weight: 300;
}

img {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

button {
  background-color: transparent;
}

strong {
  font-weight: 700;
}

section {
  margin-bottom: 10rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.wrapper {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  font-size: 1.6rem;
  line-height: 2.4rem;
  overflow: hidden;

  // @media (max-width: $md4) {
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  // }
}

.visually-hidden {
  position: absolute !important;
  clip: rect(0.1rem 0.1rem 0.1rem 0.1rem);
  /* IE6, IE7 */
  clip: rect(0.1rem, 0.1rem, 0.1rem, 0.1rem);
  padding: 0 !important;
  border: 0 !important;
  height: 0.1rem !important;
  width: 0.1rem !important;
  overflow: hidden;
}

.container {
}

.lock {
  overflow: hidden;

  @media (max-width: $md3) {
    overflow: visible;
  }
}

.hidden-content {
  display: none !important;
}

.client {
  position: relative;
  flex: 0 1 83%;
  min-height: 100vh;
  height: 100%;
  z-index: 2;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  max-height: 100vh;

  &::-webkit-scrollbar {
    display: none;
  }

  &--full {
    flex: 1 1 100%;
  }

  @media (max-width: $md2) {
    overflow-y: auto;
    overflow-x: hidden;
  }

  @media (max-width: $md3) {
    padding-top: 10rem;
    flex: 1 1 100%;
  }
}

.game-client {
  position: absolute;
  z-index: -1;
  height: 100%;
  width: 100%;
  max-height: 100vh;
  top: 0;
  left: 0;

  @media (max-width: $md3) {
    margin-top: 10rem;
    height: calc(100% - 10rem);
  }

  &--active {
    z-index: 1;
  }

  iframe {
    height: calc(100% - 0.7rem);
    width: 100%;
  }
}

body.landing {
  font-size: 62.5% !important;
  font-weight: 500 !important;

  section {
    margin-bottom: 0 !important;
  }
}
