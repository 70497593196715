@import "../abstracts/variables.scss";

.install_pwa {
  border: 0.1rem solid $orangecolor;
  border-radius: 15rem;
  padding: 5rem 10rem;
  display: block;
  color: $whitecolor;
  margin-top: 10rem;
  font-weight: 300;
  font-size: 10rem;
  text-transform: uppercase;
}
