@import "../abstracts/variables.scss";

.tabs {
  width: 100%;
  border-bottom: 0.1rem solid $darkpurplecolor;
  display: flex;
  align-items: center;
  margin-bottom: 3.3rem;
  padding-left: 1.4rem;

  &--more-padding {
    padding-left: 11.4rem;
  }

  &__tab {
    font-family: "Grotesk";
    font-size: 2.1rem;
    line-height: 2.8rem;
    text-transform: uppercase;
    position: relative;
    padding: 3.6rem;
    padding-bottom: 2.6rem;
    color: $darkpurplecolor;
    transition: 0.1s ease color;
    cursor: pointer;

    &::after {
      content: "";
      opacity: 0;
      visibility: hidden;
      height: 0.3rem;
      width: 70%;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      background: $whitecolor;
      transition: 0.1s ease opacity, 0.1s ease visibility;
      position: absolute;
    }

    &:hover {
      color: #ccbac7;
    }

    &--active {
      color: $whitecolor !important;

      &::after {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
