@import "../../abstracts/variables.scss";

.item {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-left: 4rem;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  button {
    max-height: 2.7rem;
  }

  svg {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-60%);
    max-height: 2.6rem;
    max-width: 2.6rem;

    path {
      fill: $orangecolor;
    }
  }

  & > span {
    bottom: -1rem;
    right: 0;
    font-size: 1rem;
    position: absolute;
    text-transform: uppercase;
    color: $orangecolor;
    font-weight: 500;
  }

  &__main {
    display: flex;
    align-items: center;
    white-space: nowrap;
    color: $whitecolor;
    font-size: 2rem;
    line-height: 2.2rem;
  }

  &__secondary {
    display: block;
    text-transform: uppercase;
    color: $darkpurplecolor;
    font-size: 1.4rem;
    line-height: 1.8rem;
    white-space: nowrap;
  }

  &__text {
  }
}
