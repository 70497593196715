@import "../abstracts/variables.scss";

.select_language {
  position: absolute;
  background: $whitecolor;
  height: 3.7rem;
  padding: 0.8rem;
  font-size: 2rem;
  font-family: "Grotesk";
  color: $darkcolor;
  right: 10rem;
  top: 1rem;
  border-radius: 1rem;
  align-items: center;
  text-transform: uppercase;
  z-index: 3;
  max-width: 6.5rem;
  width: 100%;

  span {
    font-weight: 500;
  }

  svg {
    height: 2rem;
    width: 2rem;
    margin-right: 0.5rem;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &__list {
    position: absolute;
    display: none;
    background: $whitecolor;
    border-radius: 1rem;
    top: 4rem;
    left: 0;
    overflow: hidden;

    &--open {
      display: block;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 0.8rem;
    cursor: pointer;
    transition: 0.1s ease background;

    &:hover {
      background: #e4e4e4;
    }
  }

  @media (max-width: $md3) {
    position: relative;
    top: 0;
    left: 0;
    margin-right: 2rem;
    height: 5rem;
    font-size: 3.6rem;
  }
}
