@import "../abstracts/variables.scss";

.secret_phrase {
  max-width: 40rem;
  width: 100%;
  position: relative;
  margin-bottom: 2.1rem;

  $self: &;

  &--blur {
    user-select: none;
    #{$self}__lock {
      opacity: 1;
      visibility: visible;
    }

    #{$self}__content {
      filter: blur(0.3rem);
    }
  }

  &__content {
    font-size: 1.8rem;
    line-height: 2.8rem;
    padding: 1.5rem 2.5rem;
    background: $darkgraycolor;
    border-radius: 0.4rem;
    width: 100%;
    color: $whitecolor;
    transition: 0.3s ease filter;
  }

  &__label {
    color: $darkpurplecolor;
    font-size: 1.4rem;
    line-height: 2.8rem;
    text-transform: uppercase;
    margin-bottom: 0.7rem;
  }

  &__lock {
    opacity: 0;
    visibility: hidden;
    transition: 0.3s ease opacity, 0.3s ease visibility;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
