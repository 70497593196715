.game_overlay {
  opacity: 0;
  visibility: hidden;
  transition: 0.5s ease opacity, 0.5s ease visibility;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgb(22 8 27 / 80%);
  z-index: 6;

  &--visible {
    visibility: visible;
    opacity: 1;
  }

  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
