// Default font //
$fontfamily: "Roboto Condensed";

// Colors //
$whitecolor: #ffffff;
$blackcolor: #000000;
$orangecolor: #eb4a41;
$darkpurplecolor: #74566c;
$darkcolor: #180f1a;
$lightpurplecolor: #442b4a;
$graycolor: #504153;
$lightcolor: #eff2f9;
$darkbluecolor: #010101;
$urbancolor: #707070;
$disabledcolor: #cdcdcd;
$disabledtextcolor: #888888;
$darkgraycolor: #332736;
$greencolor: #7aac10;
$errorcolor: #ff4c4c;
$bluecolor: #0e8ed7;
$ciancolor: #4ef9ff;

$orangegradient: linear-gradient(180deg, #ff7854 0%, #e73b4b 100%);

$commoncolor: #908992;
$uncommoncolor: #46b064;
$rarecolor: #08b8d2;
$epiccolor: #a252aa;
$legendarycolor: #ffbb37;

$commongradient: linear-gradient(180deg, #756e76 0%, #4d464e 100%);
$uncommongradient: linear-gradient(180deg, #58c46a 0%, #137954 100%);
$raregradient: linear-gradient(180deg, #09cbde 0%, #0371a4 100%);
$epicgradient: linear-gradient(180deg, #bf60bc 0%, #6a3278 100%);
$legendarygradient: linear-gradient(180deg, #ffb25c 0%, #ff8047 100%);
$boxesgradient: linear-gradient(180deg, #ff7854 0%, #e73b4b 100%);

// Breakpoints //

$minwidth: 320px;
$mw: 1152;
$md1: 1599.98px;
$md2: 1279.98px;
$md3: 1023.98px;
$md4: 767.98px;
$md5: 500px;
