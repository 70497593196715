@import "../abstracts/variables.scss";

.profile {
  width: 100%;
  position: relative;
  color: $whitecolor;
  flex: 1 1 100%;

  &__bg {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__content {
    position: relative;
    z-index: 2;
    padding: 4rem 6.5rem;
    display: flex;

    @media (max-width: $md3) {
      display: block;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 4.1rem;
  }

  &__icon {
    margin-right: 3.3rem;
    perspective: 10rem;
    transition: transform 7s linear;
    animation-duration: 7s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    animation-name: iconAnimation;
    transform: rotate3d(0, 1, 0, -25deg);
  }

  &__title {
    font-family: "Grotesk";
    font-size: 4rem;
    font-weight: 300;
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;

    button {
      font-family: $fontfamily;
      margin-left: 2rem;
    }
  }

  &__subtitle {
    font-size: 2.8rem;
    font-weight: 300;
    text-transform: uppercase;
    margin-bottom: 2rem;
  }

  &__col {
    max-width: 46rem;
    width: 100%;

    @media (max-width: $md3) {
      display: flex;
      max-width: 100%;
    }

    &:not(:last-child) {
      margin-right: 20rem;
    }
  }

  &__wrapper {
    @media (max-width: $md3) {
      margin-right: 30%;
    }
  }

  &__promocode {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 8rem;

    input {
      margin-bottom: 3rem;
    }
  }

  &__wallet {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;

    & > div {
      margin-bottom: 0;
    }

    span {
      display: flex;
      align-items: center;
      font-size: 2rem;
      text-transform: none;

      @media (max-width: $md3) {
        font-size: 2.6rem;
      }

      svg {
        margin-left: 1rem;
      }
    }
  }

  &__secret_phrase {
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;
    height: 26.8rem;
  }

  &__button {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__balance {
    display: flex;
    align-items: center;
    width: 70%;

    svg {
      margin-right: 1.2rem;
    }
  }

  &__marketing {
    display: flex;
    align-items: center;
    justify-content: space-between;

    div {
      margin-bottom: 0 !important;
      margin-right: 2rem;
    }
  }

  &__notifications {
  }

  &__subtitle {
    display: flex;
    text-transform: uppercase;
    font-size: 2.8rem;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3rem;

    svg {
      fill: $whitecolor;
    }
  }

  &__notifications_row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      line-height: 2.4rem;
      font-size: 1.6rem;
      max-width: 34rem;
    }
  }
}

@keyframes iconAnimation {
  0% {
    transform: rotate3d(0, 1, 0, -25deg);
  }

  50% {
    transform: rotate3d(0, 1, 0, 25deg);
  }

  100% {
    transform: rotate3d(0, 1, 0, -25deg);
  }
}
