.list {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  a {
    transition: 0.1s ease transform;

    &:not(:last-child) {
      margin-right: 2.5rem;
    }

    svg {
      max-width: 3rem;
    }

    svg linearGradient {
      transition: 0.1s ease all;
    }

    &:hover {
      transform: scale(1.1);
      svg linearGradient {
        stop:first-child {
          stop-color: #ff7854;
        }

        stop:last-child {
          stop-color: #e73b4b;
        }
      }
    }
  }
}
