@import "../abstracts/variables.scss";

.ratings {
  position: relative;
  height: 100%;
  width: 100%;
  flex: 1 1 100%;

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &__content {
    position: relative;
    z-index: 2;
    color: $whitecolor;
    padding: 5rem 8rem;
    min-height: 100vh;

    @media (max-width: $md3) {
      padding-left: 2rem;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 3rem;
  }

  &__icon {
    margin-right: 1rem;
    perspective: 10rem;
    transition: transform 7s linear;
    animation-duration: 7s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    animation-name: iconAnimation;
    transform: rotate3d(0, 1, 0, -25deg);
    width: 5rem;
    height: 5rem;

    svg {
      height: 100%;
      width: 100%;
    }
  }

  &__title {
    display: flex;
    flex-direction: column;
    font-family: "Grotesk";
    font-weight: 300;
    text-transform: uppercase;
    font-size: 4rem;

    span {
      font-family: $fontfamily;
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
    }
  }

  &__table {
  }

  &__headings {
    color: #614c65;
    font-size: 1.4rem;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 30rem;
    margin-left: 1.5rem;
    padding: 1rem 3rem;
    padding-left: 0;

    @media (max-width: $md3) {
      max-width: 47rem;
      font-size: 2.6rem;
    }

    span {
      &:not(:last-child) {
        margin-right: 3rem;
      }

      &:first-child {
        @media (max-width: $md3) {
          margin-right: 11rem;
        }
      }
    }
  }

  &__label {
  }

  &__list {
    max-width: 34rem;
    padding-right: 2rem;
    max-height: 80vh;
    overflow-x: hidden;
    overflow-y: auto;

    @media (max-width: $md3) {
      max-width: 50rem;
    }
  }

  &__item_frame {
    @media (max-width: $md3) {
      height: 14.6rem !important;
      width: 14.3rem !important;
    }
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    position: relative;
    padding: 1rem 3rem;
    padding-left: 0;

    &--active {
      cursor: default;

      &::before {
        content: "";
        background: #332736;
        position: absolute;
        top: 52%;
        transform: translateY(-53.5%);
        right: 0;
        width: 90%;
        height: 76%;
        z-index: -1;
        border-radius: 0.4rem;
      }
    }
  }

  &__frame {
    width: 7.3rem;
    height: 6.6rem;
    position: relative;
    z-index: 2;

    svg {
      height: 100%;
      width: 100%;
    }
  }

  &__avatar {
    position: absolute;
    z-index: 3;
    height: 100%;
    width: 100%;
    transform: translateY(0.4rem);
  }

  &__pos {
    font-size: 2rem;
    text-transform: uppercase;

    @media (max-width: $md3) {
      font-size: 3.5rem;
    }
  }

  &__earned {
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    font-size: 2rem;

    @media (max-width: $md3) {
      font-size: 3.5rem;
    }

    span {
      @media (max-width: $md3) {
        font-size: 2.7rem;
        line-height: 4rem;
      }
      font-size: 1.4rem;
      color: #614c65;
    }
  }

  &__car {
    position: absolute;
    z-index: 3;
    height: 100%;
    width: 73%;
    top: 0;
    right: 0;
  }
}

@keyframes iconAnimation {
  0% {
    transform: rotate3d(0, 1, 0, -25deg);
  }

  50% {
    transform: rotate3d(0, 1, 0, 25deg);
  }

  100% {
    transform: rotate3d(0, 1, 0, -25deg);
  }
}
