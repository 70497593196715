@import "../abstracts/variables.scss";

.how_to_play {
  width: 100%;
  position: relative;
  padding: 5rem 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 100%;

  $self: &;

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    img {
      object-fit: fill;
    }
  }

  &__container {
    width: 100%;
    z-index: 2;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(24, 15, 26, 0.8);
    border-radius: 10rem;
    padding: 1rem 1.2rem;
    max-width: 41rem;
    width: 100%;
    position: absolute;
    cursor: pointer;
    z-index: 2;
    transition: 0.1s ease box-shadow;

    &:hover {
      box-shadow: 0 0 1rem $bluecolor;

      #{$self}__number {
        background: $bluecolor;
        color: $whitecolor;
      }
    }
  }

  &__content {
    display: flex;
    align-items: center;
  }

  &__number {
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    background: $orangecolor;
    color: $darkcolor;
    margin-right: 2rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 2.8rem;
    font-weight: 500;
    transition: 0.1s ease color, 0.1s ease background;
  }

  &__title {
    text-transform: uppercase;
    color: $whitecolor;
    font-size: 2rem;
  }

  &__arrow {
    display: inline-flex;
    align-items: center;

    svg {
      height: 100%;
      fill: $orangecolor;
    }
  }
}
