@import "../../abstracts/variables.scss";

.history_item {
  display: flex;
  align-items: center;
  background: $darkcolor;
  color: $whitecolor;
  font-size: 2rem;
  text-transform: uppercase;
  border-radius: 0.4rem;
  width: 100%;
  max-width: 92.2rem;
  padding: 2.2rem 4.4rem;
  font-weight: 500;

  &:not(:last-child) {
    margin-bottom: 0.6rem;
  }

  &__number {
    flex: 0 1 22%;
  }

  &__prize {
    flex: 0 1 23%;
  }

  &__racers {
    flex: 0 1 13%;
  }

  &__date {
    flex: 0 1 22%;
  }

  &__button {
    width: 100%;
    max-width: 13rem;
  }
}
