@import "../../../styles/abstracts/variables.scss";

.new {
  background: $darkbluecolor;
  position: sticky;
  top: 0;
  height: 350vh;

  @media (max-width: $md0) {
    height: 430vh;
  }

  @media (max-width: $md2) {
    position: static;
    height: auto;
  }

  $self: &;

  :global(.landing-container) {
    @media (max-width: $md2) {
      padding: 0 2rem;
    }
  }

  // &__title_row {
  //   margin-bottom: 8rem;
  // }

  &__wrapper {
    overflow: hidden;
    position: relative;
    height: 100%;
    padding-top: 11rem;
    padding-bottom: 16rem;

    @media (max-width: $md2) {
      padding-top: 5rem;
      padding-bottom: 5rem;
    }
  }

  &__separator {
    position: absolute;
    bottom: -6rem;
    width: 100%;

    @media (max-width: $md1) {
      bottom: -4rem;
    }

    @media (max-width: $md2) {
      bottom: -3rem;
    }

    @media (max-width: $md3) {
      bottom: -2rem;
    }
  }

  &__container {
    // max-height: 49rem;
    // overflow: hidden;
    position: relative;
    top: -6rem;
  }

  &__slide {
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
    padding: 4rem 0;
    padding-left: 2rem;
    height: 100%;
    justify-content: space-between;

    &.active {
      #{$self}__content {
        opacity: 1;
        transform: translateY(0);
      }

      #{$self}__image {
        opacity: 1;
      }
    }

    &.bottom {
      #{$self}__content {
        transform: translateY(30rem);

        @media (max-width: $md2) {
          transform: translateY(0);
        }
      }
    }

    @media (max-width: $md2) {
      flex-direction: column;
      padding: 0;
      transform: unset !important;
    }
  }

  &__content {
    max-width: 42.7rem;
    margin-right: 8rem;
    opacity: 0;
    transition: 0.7s ease all;
    transform: translateY(-30rem);

    @media (max-width: $md2) {
      margin-right: 0;
      max-width: unset;
      margin-bottom: 3rem;
      transform: unset;
      opacity: 1;
      width: 100%;
    }
  }

  &__number {
    background-image: linear-gradient(180deg, #ff7854 0%, #e73b4b 100%);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    margin-bottom: 0.6rem;
    font-family: Hacked;
    font-size: 4.8rem;
    line-height: 5.5rem;
  }

  &__title {
    font-weight: 600;
    font-size: 2.8rem;
    line-height: 3.8rem;
    margin-bottom: 3rem;

    @media (max-width: $md2) {
      font-size: 2.4rem;
      line-height: 3.2rem;
      margin-bottom: 1rem;
    }
  }

  &__text {
    color: #868f97;
    font-weight: 500;

    @media (max-width: $md2) {
      font-size: 1.3rem;
      line-height: 1.9rem;
    }
  }

  &__image {
    border: 1rem solid #191a22;
    border-radius: 1.6rem;
    background: $darkcolor;
    max-width: 69rem;
    width: 100%;
    height: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: 0.7s ease opacity;
    width: 67.3rem;
    height: 44.5rem;

    &.small {
      img {
        height: 21.4rem;
        width: 21.4rem;
      }
    }

    @media (max-width: $md2) {
      width: 45rem;
      height: 30rem;
      position: absolute;
      right: -10%;
    }

    @media (max-width: $md2) {
      position: static;
      height: 27rem;
      width: 100%;
      right: unset;
      opacity: 1;
    }
  }

  &__pluses {
    position: absolute;
    right: 0;
    top: 27rem;
  }
}
