@import "../../styles/abstracts/variables.scss";

.button {
  color: $defaultwhitecolor;
  font-weight: 500;
  padding: 1rem;
  border-radius: 30rem;
  background: $orangegradient;
  background-size: 150%;
  transition: 0.5s ease background-position, 0.1s ease transform;
  width: 100%;
  cursor: pointer;
  font-family: Hacked;
  font-size: 3rem;

  &:hover {
    background-position: 100%;
  }

  &:active {
    transform: scale(0.99);
  }

  &.small {
    padding: 1rem 1.4rem;
  }

  &.outlined {
    padding: 0.2rem;

    &:hover {
      span {
        background: transparent;
      }
    }

    span {
      background: $darkcolor;
      border-radius: 10rem;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      padding: 1.4rem 1.5rem;
      white-space: nowrap;
      transition: 0.1s ease background;
    }

    &.small {
      span {
        padding: 0.8rem 1.2rem !important;
      }
    }
  }

  // &.oulined
}
