@import "../../../../styles/abstracts/variables.scss";

.item {
  display: flex;
  align-items: center;

  @media (max-width: $md3) {
    flex-direction: column-reverse;
  }

  $self: &;

  &:not(:last-child) {
    margin-bottom: 9rem;

    @media (max-width: $md2) {
      margin-bottom: 4rem;
    }

    @media (max-width: $md3) {
      margin-bottom: 3rem;
    }
  }

  &:nth-child(even) {
    flex-direction: row-reverse;

    @media (max-width: $md3) {
      flex-direction: column-reverse;
    }

    #{$self}__content {
      margin-right: 0;
    }

    #{$self}__picture {
      margin-right: 8rem;

      @media (max-width: $md2) {
        margin-right: 3rem;
      }

      @media (max-width: $md3) {
        margin-right: 0;
      }
    }
  }

  &__content {
    margin-right: 8rem;

    @media (max-width: $md2) {
      margin-right: 3rem;
      max-width: 33.5rem;
      overflow: hidden;
    }

    @media (max-width: $md3) {
      margin-right: 0;
      max-width: 100%;
    }
  }

  &__title {
    background-image: linear-gradient(180deg, #ff7854 0%, #e73b4b 100%);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    margin-bottom: 1.5rem;
    font-family: Hacked;
    font-size: 3.6rem;
    line-height: 4.2rem;

    @media (max-width: $md2) {
      margin-bottom: 1rem;
      font-size: 2.4rem;
      line-height: 2.8rem;
    }
  }

  &__separator {
    margin-bottom: 3rem;

    @media (max-width: $md2) {
      margin-bottom: 1rem;
    }
  }

  &__text {
    font-weight: 500;

    @media (max-width: $md2) {
      font-size: 1.3rem;
      line-height: 2rem;
    }
  }

  &__picture {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    min-width: 69rem;

    overflow: hidden;

    @media (max-width: $md1) {
      min-width: 49rem;
    }

    @media (max-width: $md2) {
      min-width: 36.4rem;
    }

    @media (max-width: $md3) {
      min-width: unset;
      max-width: 33.5rem;
      width: 100%;
      overflow: hidden;
    }
  }

  &__frame {
    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__image {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 2.8rem;

    @media (max-width: $md3) {
      padding: 1.5rem;
      margin-top: -0.2rem;
      top: 0;
    }
  }
}
