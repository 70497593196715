@import "../abstracts/variables.scss";

.sidebar {
  max-width: 36.7rem;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: $darkcolor;
  color: $whitecolor;
  font-size: 1.8rem;
  line-height: 2.1rem;
  flex: 0 1 19%;
  max-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 3;

  @media (max-width: $md3) {
    position: fixed;
    opacity: 0;
    visibility: hidden;
    transform: translateX(-100%);
    transition: 0.3s ease all;

    &--active {
      opacity: 1;
      visibility: visible;
      transform: translateX(0);
    }
  }

  $self: &;

  &--disabled {
    overflow-y: hidden;
  }

  &__mobile_header {
    padding-left: 10rem;
    padding-right: 5rem;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
    background: $darkcolor;
    display: flex;
    align-items: center;
    border-bottom: 0.1rem solid $graycolor;
    box-shadow: 0 0 5rem rgba(#000, 0.5);
    min-height: 10rem;
    justify-content: space-between;
  }

  &__mobile_sounds {
    display: flex;
    align-items: center;
  }

  &__online {
    position: absolute;
    background: $whitecolor;
    height: 3.7rem;
    padding: 0.8rem;
    font-size: 2.6rem;
    font-family: "Grotesk";
    color: $darkcolor;
    left: 1.5rem;
    top: 1rem;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    font-weight: 400;

    @media (max-width: $md3) {
      position: relative;
      top: 0;
      left: 0;
      margin-right: 2rem;
      height: 5rem;
      font-size: 3.6rem;
    }

    &::before {
      content: "";
      border-radius: 50%;
      background: $uncommoncolor;
      margin-right: 0.7rem;
      height: 0.8rem;
      width: 0.8rem;

      @media (max-width: $md3) {
        height: 1.2rem;
        width: 1.2rem;
      }
    }
  }

  &__content {
    z-index: 3;
    padding: 0 3rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    flex: 1 1 100%;
    padding-bottom: 1.5rem;

    @media (max-width: $md3) {
      justify-content: space-between;
    }
  }

  &__wrapper {
  }

  &__header {
    position: relative;
    flex: 0 1 25%;
    margin-bottom: -6rem;

    &::after {
      content: "";
      clip-path: polygon(0 64%, 100% 86%, 100% 100%, 0% 100%);
      height: 35rem;
      width: 100%;
      position: absolute;
      bottom: -0.2rem;
      left: 0;
      background: $darkcolor;
    }

    @media (max-width: $md3) {
      img {
        display: none;
      }
    }
  }

  &__logo {
    padding: 1rem 3.2rem;
    padding-top: 0;

    svg {
      margin-right: 2.5rem;

      height: 6rem;
    }

    p {
      display: flex;
      color: $orangecolor;
      font-size: 1.4rem;
      line-height: 1.8rem;
      text-transform: uppercase;

      span {
        font-weight: 400;
        display: block;

        &:not(:last-child) {
          margin-right: 0.3rem;
        }
      }
    }
  }

  &__top {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1rem;
    margin-top: 0.5rem;

    svg {
      width: 20rem;
      height: 7rem;
    }
  }

  &__title {
    font-size: 3rem;
    line-height: 3rem;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
  }

  &__subtitle {
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    color: $darkpurplecolor;
    font-size: 1.4rem;
    line-height: 1.8rem;
  }

  &__nav {
    padding-bottom: 1.2rem;
    margin-bottom: 1rem;
    max-height: 33rem;
    flex: 0 1 50%;

    @media (max-width: $md3) {
      max-height: 45rem;
      padding-bottom: 5rem;
      margin-bottom: 0;
    }
  }

  &__nav_list {
    position: relative;
    margin-left: -1rem;
  }

  &__nav_arrow {
    position: absolute;
    left: 0;
    top: 0;
    transition: 0.3s ease top;
  }

  &__nav_item {
    text-transform: uppercase;
    transition: 0.3s ease padding-bottom;
    position: relative;
    cursor: pointer;

    @media (max-width: $md3) {
      font-size: 2.6rem;
    }

    &:not(:last-child) {
      margin-bottom: 1.8rem;

      @media (max-width: $md3) {
        margin-bottom: 4rem;
      }
    }

    a {
      color: $whitecolor;
      transition: 0.3s ease color;
      display: inline-flex;
      align-items: center;
      padding-left: 3.2rem;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }
    }

    svg {
      position: absolute;
      top: 50%;
      transform: translate(1rem, -50%);
    }

    p {
      color: $darkpurplecolor;
      font-size: 1.4rem;
      line-height: 1.8rem;
      padding: 0 4rem;
    }

    &--active {
      a {
        color: $orangecolor !important;
      }
    }

    // &:hover {
    //   padding-bottom: 6.2rem;

    //   a {
    //     color: #e78c87;
    //   }
    // }
  }

  &__connect {
    padding: 3rem;
    p {
      color: $darkpurplecolor;
      font-size: 1.4rem;
      line-height: 1.8rem;
      margin-bottom: 2rem;
    }
  }

  &__info {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    border-top: 0.1rem solid $lightpurplecolor;
    border-bottom: 0.1rem solid $lightpurplecolor;
    flex: 0 1 20%;
    min-height: 29rem;
    margin-bottom: auto;

    @media (max-width: $md3) {
      display: flex;
      min-height: unset;
      border: 0;
      margin-bottom: 0;
    }

    button {
      margin-top: 0.5rem;
    }
  }

  &__info_buttons {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-left: 2rem;
  }

  &__info_wrapper {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__info_content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      width: 100%;

      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }

  &__info_item {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-left: -1rem;
    position: relative;
    align-items: center;

    &:hover {
      #{$self}__info_icon svg {
        fill: $orangecolor;
      }
    }

    &--wallet {
      justify-content: flex-start;
      cursor: pointer;
      transition: 0.2s ease padding;
      flex-direction: column;
      height: 6.11rem;
      overflow: hidden;

      @media (max-width: $md3) {
        height: unset;
        padding-bottom: 0;
        flex-direction: row;
      }

      #{$self}__info_left {
        align-items: center;
      }
    }

    &--no-buttons {
      padding-bottom: 0;
      height: unset;
      margin-left: 0;
      margin-bottom: 3rem !important;

      #{$self}__info_wrapper {
        flex-direction: column;
      }

      #{$self}__info_left {
        width: 100%;
        margin-right: 0 !important;
        flex-wrap: nowrap;

        & > div {
          margin-right: auto;
        }

        button {
          max-width: 10rem;

          &:last-child {
            margin-left: 0;
          }
        }

        &:first-child {
          &::after {
            display: none;
          }
        }
      }
    }

    &--ingame {
      align-items: center;

      #{$self}__info_left {
        align-items: center;

        &:first-child {
          margin-right: 2rem;
        }
      }
    }

    button {
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }

    &:not(:last-child) {
      margin-bottom: 2rem;

      @media (max-width: $md3) {
        margin-bottom: 0;
        margin-right: 3rem;
      }
    }
  }

  &__info_icon {
    margin: 0;
    margin-left: 0.5rem;

    a {
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }
    }

    svg {
      fill: $graycolor;
      transition: 0.1s ease fill;

      @media (max-width: $md3) {
        height: 4rem;
        width: 4rem;
        top: 1.7rem;
        position: relative;
      }
    }
  }

  &__info_left {
    display: flex;
    align-items: center;
    padding-bottom: 0.7rem;
    flex-wrap: wrap;

    @media (max-width: $md3) {
      flex-wrap: nowrap;
    }

    button {
      max-width: unset;
      width: 100%;
    }

    svg {
      margin-right: 1.2rem;
      margin-top: 1.4rem;

      @media (max-width: $md3) {
        margin-top: 3.4rem;
      }
    }

    &--no-margin {
      svg {
        margin-top: 0;
      }
    }
  }

  &__info_title {
    display: block;
    text-transform: uppercase;
    color: $darkpurplecolor;
    font-size: 1.4rem;
    line-height: 1.8rem;
    white-space: nowrap;

    @media (max-width: $md3) {
      font-size: 2.6rem;
      line-height: 3.4rem;
    }

    &--additional {
      @media (max-width: $md3) {
        display: none;
      }
    }

    &--hidden {
      opacity: 0;
      visibility: hidden;
    }
  }

  &__info_data {
    display: flex;
    align-items: center;
    white-space: nowrap;
    color: $whitecolor;

    @media (max-width: $md3) {
      font-size: 2.8rem;
    }

    svg {
      cursor: pointer;
      margin: 0;
      margin-left: 0.5rem;
      opacity: 0.5;
      transition: 0.1s ease opacity;
      position: relative;
      top: -0.075rem;

      &:hover {
        opacity: 1;
      }
    }
  }

  &__social {
    margin-top: 1rem;
    flex: 0 1 5%;

    @media (max-width: $md3) {
      padding-top: 2rem;
      border-top: 0.1rem solid $graycolor;
    }
  }

  &__social_title {
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: $darkpurplecolor;
    margin-bottom: 1.8rem;
    text-transform: uppercase;
  }

  &__social_row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      transition: 0.1s ease transform;

      svg linearGradient {
        transition: 0.1s ease all;
      }

      &:hover {
        transform: scale(1.1);
        svg linearGradient {
          stop:first-child {
            stop-color: #ff7854;
          }

          stop:last-child {
            stop-color: #e73b4b;
          }
        }
      }
    }
  }

  &__balance {
    position: absolute;
    font-size: 1.2rem;
    bottom: -1.5rem;
    left: 3.5rem;
  }

  &__mute_sound,
  &__mute_music {
    top: 1rem;
    right: 1rem;
    position: absolute;
    z-index: 2;
    cursor: pointer;

    @media (max-width: $md3) {
      position: relative;
      right: unset !important;
      top: unset !important;
      height: 5rem;
      width: 5rem;

      svg {
        height: 100%;
        width: 100%;
      }
    }

    svg {
      opacity: 0.5;
    }

    &:hover {
      svg {
        opacity: 0.75;
      }
    }

    &--active {
      svg {
        opacity: 1;
      }
    }
  }

  &__mute_music {
    right: 5.5rem;

    @media (max-width: $md3) {
      margin-right: 2rem;
    }
  }
}
