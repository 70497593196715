@import "../../../styles/abstracts/variables.scss";

.about {
  padding-top: 6rem;
  position: relative;
  padding-bottom: 14rem;
  overflow: hidden;

  @media (max-width: $md2) {
    padding-bottom: 4rem;
  }

  &__separator {
    width: 100%;
    position: absolute;
    bottom: -5rem;
    z-index: 2;

    @media (max-width: $md1) {
      bottom: -4rem;
    }

    @media (max-width: $md2) {
      bottom: -3rem;
    }

    @media (max-width: $md3) {
      bottom: -2rem;
    }
  }
}
