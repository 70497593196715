@import "../../abstracts/variables.scss";

.distribution {
  max-width: 105rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  &__title {
    font-size: 2.4rem;
    font-family: "Grotesk";
    font-weight: 300;
    text-transform: uppercase;
    margin-bottom: 3rem;
  }

  &__pool {
    font-family: "Grotesk";
    font-size: 5rem;
    border-bottom: 0.1rem solid $graycolor;
    padding: 0 6rem;
    padding-bottom: 2rem;
    margin-bottom: 2.4rem;

    span {
      &:first-child {
        margin-right: 1.5rem;
      }

      &:last-child {
        margin-left: 1.5rem;
      }
    }

    svg {
      width: 4.7rem;
      height: 3.7rem;
    }
  }

  &__subtitle {
    font-family: "Grotesk";
    text-transform: uppercase;
    font-size: 2rem;
    margin-bottom: 2.2rem;
  }

  &__list {
    width: 100%;
  }

  &__row {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 1.4rem;
    width: 100%;

    &--double {
      grid-template-columns: 1fr 1fr !important;
    }

    &--fourth {
      grid-template-columns: repeat(4, 1fr) !important;
    }

    &:not(:last-child) {
      margin-bottom: 1.4rem;
    }

    &:first-child {
      grid-template-columns: 1fr;
    }

    &:nth-child(2) {
      grid-template-columns: 1fr 1fr;
    }

    &:nth-child(3) {
      grid-template-columns: repeat(3, 1fr);
    }

    &:nth-child(4) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &__item {
    background: rgba(14, 6, 16, 0.8);
    border-radius: 0.4rem;
    height: 9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &--first {
      background: rgba(51, 45, 28, 0.8);
    }

    &--second {
      background: rgba(31, 42, 49, 0.8);
    }

    &--third {
      background: rgba(44, 25, 15, 0.8);
    }
  }

  &__prize {
    font-size: 2rem;

    span {
      &:first-child {
        margin-right: 0.5rem;
      }

      &:last-child {
        margin-left: 0.5rem;
      }
    }
  }

  &__place {
    color: $darkpurplecolor;
    text-transform: uppercase;
  }

  &__text {
    font-size: 2rem;
    text-align: center;
    width: 100%;
    margin-top: 3.5rem;
    text-transform: uppercase;
    font-family: "Grotesk";
  }
}
