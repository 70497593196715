@import "../../abstracts/variables.scss";

.withdraw {
  max-width: 61rem;
  border-radius: 0.4rem;
  background: $darkcolor;
  box-shadow: 0 0 3rem 0 rgba(41, 242, 217, 0.8);
  color: $whitecolor;
  padding: 7rem;
  padding-bottom: 6rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__title {
    text-transform: uppercase;
    font-size: 3rem;
    font-family: "Grotesk";
    margin-bottom: 2rem;
    text-align: center;
  }

  &__text {
    color: $darkpurplecolor;
    line-height: 2.8rem;
    font-size: 1.6rem;
    text-transform: uppercase;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3rem;
    flex-direction: column;

    span {
      color: $whitecolor;
      display: block;
    }
  }

  &__row {
    display: flex;
    align-items: flex-end;
    margin-bottom: 1rem;
    width: 100%;

    button {
      max-width: 12rem;
    }

    &--margin {
      position: relative;

      input {
        padding-right: 10rem;
      }

      button {
        position: absolute;
        z-index: 2;
        top: 57%;
        transform: translateY(-50%);
        right: 1rem;
        bottom: unset;
      }
    }

    &--address {
      input {
        padding-right: 13rem;
      }
    }
  }

  &__input {
    width: 100%;
  }

  &__price {
    display: flex;
    flex-direction: column;
    border-top: 0.1rem solid $darkpurplecolor;
    width: 100%;
    padding-top: 1rem;
    color: $darkpurplecolor;
    text-transform: uppercase;
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin-bottom: 2rem;

    span {
      color: $whitecolor;
    }
  }

  &__tabs {
    margin-bottom: 2rem;
  }

  &__message {
    width: 100%;
    color: $darkpurplecolor;
    text-transform: uppercase;
    margin-bottom: 2rem;
    font-size: 1.6rem;
    line-height: 2.4rem;

    span {
      color: $whitecolor;
    }
  }

  &__balance {
    width: 100%;
    margin-bottom: 2rem;

    span {
      color: $darkpurplecolor;
      text-transform: uppercase;
      font-size: 1.4rem;
    }

    div {
      display: flex;
      align-items: center;
    }

    svg {
      margin-right: 0.5rem;
    }
  }
}
