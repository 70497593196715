@import "../../abstracts/variables.scss";

.invite_friends {
  width: 100%;
  position: relative;
  color: $whitecolor;
  flex: 1 1 100%;

  &__bg {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__back {
  }

  &__content {
    position: relative;
    z-index: 2;
    display: flex;
    padding: 0 7rem;
    padding-left: 13.4rem;
    padding-top: 10rem;
  }

  &__col {
    &:first-child {
      max-width: 40rem;
      margin-right: 17rem;
    }
  }

  &__top {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 1.5rem;
  }

  &__icon {
    margin-right: 3.3rem;
    position: absolute;
    perspective: 10rem;
    transition: transform 7s linear;
    animation-duration: 7s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    animation-name: iconAnimation;
    transform: rotate3d(0, 1, 0, -25deg);
    left: -9rem;
  }

  &__title {
    font-size: 4rem;
    text-transform: uppercase;
    font-family: "Grotesk";
  }

  &__text {
    color: $darkpurplecolor;
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-transform: uppercase;
    margin-bottom: 4rem;
  }

  &__code {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 5rem;
  }

  &__subtitle {
    font-size: 2.8rem;
    font-weight: 300;
    text-transform: uppercase;
    margin-bottom: 3rem;
  }
}

@keyframes iconAnimation {
  0% {
    transform: rotate3d(0, 1, 0, -25deg);
  }

  50% {
    transform: rotate3d(0, 1, 0, 25deg);
  }

  100% {
    transform: rotate3d(0, 1, 0, -25deg);
  }
}
