@import "../abstracts/variables.scss";

.game_popup {
  z-index: 5;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  // align-items: center;
  justify-content: center;
  background-color: rgba(22, 8, 27, 0.8);
  overflow-y: auto;
  overflow-x: hidden;

  @media (max-width: $md3) {
    position: fixed;
    align-items: flex-start;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__body {
  }

  &__content {
    position: relative;
    margin: 15rem 0;
    height: fit-content;

    @media (max-width: $md3) {
      min-width: 58rem;
      margin-top: 18rem;
      margin-bottom: 10rem;
    }
  }

  &__close {
    cursor: pointer;
    position: absolute;
    top: -5.4rem;
    right: -5.1rem;
    height: 4rem;
    width: 4rem;

    svg {
      transition: 0.1s ease transform;
    }

    &:hover {
      svg {
        transform: scale(1.05);
      }
    }

    &:active {
      svg {
        transform: scale(0.95);
      }
    }
  }
}
