@import "../abstracts/variables.scss";

.download_app {
  display: none;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #180f1a;
  color: $whitecolor;
  flex-direction: column;

  @media (max-width: $md3) {
    display: flex;
  }

  &__img {
    height: 32rem;
    width: 85rem;
    margin-bottom: 20rem;

    svg {
      height: 100%;
      width: 100%;
    }
  }

  &__label {
    max-width: 120rem;
    text-align: center;
    text-transform: uppercase;
    font-size: 11rem;
    line-height: 13rem;
  }
}
