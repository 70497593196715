// html,
// body {
//   font-size: 62.5% !important;
//   background: $darkcolor !important;
//   color: $defaultwhitecolor !important;
// }

input::placeholder {
  color: rgba($defaultwhitecolor, 0.7);
}

img {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

main {
  width: 100%;
  position: relative;
}

.landing-app {
  font-size: 62.5% !important;
  background: $darkcolor !important;
  color: $defaultwhitecolor !important;
}

.landing-wrapper {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 1.6rem;
  line-height: 2.4rem;
  max-width: 144rem;
  margin: 0 auto;
  position: relative;
  font-family: "Exo";

  @media (max-width: $md2) {
    overflow: hidden;
  }
}

.visually-hidden {
  position: absolute !important;
  clip: rect(0.1rem 0.1rem 0.1rem 0.1rem);
  /* IE6, IE7 */
  clip: rect(0.1rem, 0.1rem, 0.1rem, 0.1rem);
  padding: 0 !important;
  border: 0 !important;
  height: 0.1rem !important;
  width: 0.1rem !important;
  overflow: hidden;
}

.landing-container {
  max-width: $mw + px;
  margin: 0 auto;
  padding: 0 2rem;
  width: 100%;

  &.relative {
    position: relative;
  }
}

.lock {
  overflow: hidden;
}
