@import "../../abstracts/variables.scss";

.buy {
  max-width: 61rem;
  border-radius: 0.4rem;
  background: $darkcolor;
  box-shadow: 0 0 3rem 0 rgba(41, 242, 217, 0.8);
  color: $whitecolor;
  padding: 7rem;
  padding-bottom: 6rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__title {
    text-transform: uppercase;
    font-size: 3rem;
    font-family: "Grotesk";
    margin-bottom: 2rem;
    text-align: center;
  }

  &__text {
    color: $darkpurplecolor;
    line-height: 2.8rem;
    font-size: 1.6rem;
    text-transform: uppercase;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3rem;
    flex-direction: column;

    span {
      color: $whitecolor;
      display: block;
    }
  }

  &__boost {
    margin-bottom: 5rem;
  }

  &__price {
    text-transform: uppercase;
    color: $darkpurplecolor;
    font-size: 2rem;
    margin-bottom: 4rem;
    display: flex;
    // justify-content: space-between;
    // width: 100%;
    align-items: center;

    span {
      margin-left: 1rem;
      color: $whitecolor;
    }
  }
}
