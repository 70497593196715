@import "../../abstracts/variables.scss";

.lootbox {
  border-radius: 0.4rem;
  background: $darkcolor;
  box-shadow: 0 0 3rem 0 rgba(41, 242, 217, 0.8);
  padding: 4rem 5rem;
  padding-bottom: 2rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  &__opening {
    color: $whitecolor;
    text-transform: uppercase;
    font-family: "Grotesk";
    font-size: 2.6rem;
  }

  &__title {
    font-size: 3rem;
    text-transform: uppercase;
    color: $whitecolor;
    font-family: "Grotesk";
    font-weight: 300;
    margin-bottom: 2rem;
  }

  &__image {
    margin-bottom: 3rem;
    height: 43rem;
    width: 43rem;
    position: relative;
    z-index: 1;

    & > div:first-child {
      width: 100%;
      height: 100%;

      div {
        width: 100%;
        height: 100%;
      }

      & > div > div {
        background-color: transparent !important;
      }
    }
  }

  &__text {
    font-size: 1.6rem;
    line-height: 2.8rem;
    color: $darkpurplecolor;
    text-transform: uppercase;
    margin-bottom: 1.5rem;
  }

  &__gradient {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 30rem;
    width: 40rem;
    filter: blur(8rem);
    background: rgba(41, 242, 217, 0.8);
  }

  &__button {
  }

  &__card {
    transform: scale(0) translate(-50%, -50%);
    opacity: 0;
    visibility: hidden;
    transition: 0.3s ease-out transform, 0.3s ease opacity, 0.3s ease visibility;
    position: absolute;
    top: 50%;
    left: 50%;
    max-height: 28rem;
    max-width: 17rem;
    width: 100%;

    &--scaled {
      transform: scale(1) translate(-50%, -50%);
      opacity: 1;
      visibility: visible;
    }
  }

  &__fee {
    color: $orangecolor;
    margin-bottom: 2rem;
  }

  &__error {
    color: $errorcolor;
    user-select: none;
    margin-top: 2rem;
    max-width: 40rem;
  }
}
