@import "../../styles/abstracts/variables.scss";

.footer {
  padding: 2rem 0;
  padding-top: 6rem;

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
  }

  &__link {
    text-transform: uppercase;
    font-weight: 500;

    &:hover {
      color: $orangecolor;
    }
  }

  &__social {
    max-width: 30rem;
    margin-left: auto;
  }
}
