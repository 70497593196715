@import "../abstracts/variables.scss";

.shop_card {
  color: $whitecolor;
  position: relative;
  transform: translateZ(-1000px);
  z-index: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 28.4rem;

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
  }

  &__frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
  }

  &__img {
    height: 26.1rem;
    width: 37rem;
    user-select: none;

    &--lootbox {
      width: 28.5rem;
      height: 28.2rem;
    }

    div {
      height: 100%;
      background: 0 !important;
    }
  }

  &__wrapper {
  }

  &__container {
    width: 100%;
    max-width: 42.8rem;
    margin-right: 9rem;
  }

  &__title {
    font-family: "Grotesk";
    font-weight: 300;
    text-transform: uppercase;
    font-size: 3rem;
    margin-bottom: 1rem;
    user-select: none;
    display: flex;
    align-items: center;
    white-space: nowrap;

    @media (max-width: $md3) {
      font-size: 5rem;
    }

    svg {
      margin-left: 1rem;
    }
  }

  &__text {
    color: $darkpurplecolor;
    font-size: 1.6rem;
    text-transform: uppercase;
    line-height: 2.8rem;
    user-select: none;
    width: 100%;
    margin-bottom: 1.3rem;

    @media (max-width: $md3) {
      font-size: 2.6rem;
      line-height: 3.8rem;
    }
  }

  &__cost {
    color: $whitecolor;
    font-size: 2.8rem;
    text-transform: uppercase;
    line-height: 2.8rem;
    max-width: 36.5rem;
    user-select: none;
    display: flex;
    align-items: flex-end;

    svg {
      height: 3rem;
      width: 3.8rem;
      margin-right: 1.4rem;
    }

    span {
      text-decoration: line-through;
      opacity: 0.5;
    }

    @media (max-width: $md3) {
      font-size: 2.4rem;
      line-height: 3.6rem;
    }
  }

  &__counter {
  }

  &__tx {
    text-decoration: unset !important;
    color: $darkpurplecolor;
    opacity: 1 !important;
    font-size: 2rem;
    line-height: 2rem;
    margin-left: 0.7rem;
  }

  &__total_price {
    user-select: none;
    margin-bottom: 2rem;
    max-width: 25rem;

    span {
      color: $darkpurplecolor;
      font-size: 2.8rem;
      line-height: 2.8rem;
      margin-right: 0.5rem;
      text-transform: uppercase;
    }

    b {
      text-transform: uppercase;
      font-weight: 400;
      font-size: 2.8rem;
      line-height: 2.8rem;

      span {
        color: $darkpurplecolor;
        font-size: 2.8rem;
        line-height: 2.8rem;
        font-weight: 400;
        text-decoration: line-through;
        margin-right: 1rem;
      }
    }
  }

  &__bottom_row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: column;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    position: relative;

    button {
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }

  &__fee {
    color: $darkpurplecolor;

    span {
      color: $whitecolor;
      font-size: 1.6rem;
      line-height: 1.6rem;
    }
  }

  &__error {
    position: absolute;
    bottom: -4.3rem;
    color: $errorcolor;
    user-select: none;
  }
}

@keyframes iconAnimation {
  0% {
    transform: translate(-50%, -20%, 1px) rotate3d(0, 1, 0, 0);
  }

  50% {
    transform: translate(-50%, -20%, 1px) rotate3d(0, 1, 0, 15deg);
  }

  100% {
    transform: translate(-50%, -20%, 1px) rotate3d(0, 1, 0, 0);
  }
}
