@import "../abstracts/variables.scss";

.boosts {
  flex: 0 1 50%;
  position: sticky;
  top: 0;
  padding: 3.5rem 4rem;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100%;
  padding-bottom: 8rem;

  $self: &;

  &__wrapper {
    &:not(:last-child) {
      margin-bottom: 4rem;
    }
  }

  &__message {
    text-transform: uppercase;
    text-align: center;
    font-size: 2rem;
  }

  &__icon {
    position: relative;
    margin-bottom: 1rem;
    transition: 0.3s ease transform;

    svg {
      max-width: 10.9rem;
      max-height: 10.3rem;

      path {
        fill: $graycolor;
        transition: 0.1s ease fill;
      }
    }
  }

  &__count {
    position: absolute;
    z-index: 2;
    right: 0;
    bottom: 0;
    text-align: center;
    font-size: 1.6rem;
    font-weight: 500;
    background: $orangecolor;
    border-radius: 50%;
    height: 3rem;
    width: 3rem;
    font-family: Grotesk;
    letter-spacing: -0.07rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  &__tooltip {
    text-align: center;
    background: $darkcolor;
    padding: 1rem;
    font-size: 1.4rem;
    line-height: 1.8rem;
    position: absolute;
    width: 150%;
    transition: 0.1s ease opacity, 0.1s ease visibility;
    z-index: 3;
    bottom: -45%;
    border-radius: 1.6rem;
    border: 0.1rem solid $orangecolor;
    opacity: 0;
    visibility: hidden;
  }

  &__row {
    display: flex;
  }

  &__container {
    display: flex;
    // position: relative;
  }

  &__info {
    position: sticky;
    height: fit-content;
    top: 0;
  }

  &__car {
    text-transform: uppercase;
    margin-bottom: 1.2rem;
    font-size: 1.8rem;

    span {
      color: $orangecolor;
      font-weight: 500;
    }
  }

  &__content {
    margin-right: 2rem;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    position: relative;

    &:hover {
      #{$self}__label {
        opacity: 1;
      }

      #{$self}__tooltip {
        opacity: 1;
        visibility: visible;
      }

      #{$self}__icon {
        transform: scale(1.03);
        &.blue svg path {
          fill: rgba(#1dd6fe, 0.5);
        }

        &.red svg path {
          fill: rgba(#fe5f35, 0.5);
        }

        &.green svg path {
          fill: rgba(#8ed02f, 0.5);
        }
      }
    }

    &.active {
      #{$self}__label {
        opacity: 1;
      }

      #{$self}__icon {
        transform: scale(1);

        &.blue svg path {
          fill: #1dd6fe;
        }

        &.red svg path {
          fill: #fe5f35;
        }

        &.green svg path {
          fill: #8ed02f;
        }
      }
    }

    &:not(:last-child) {
      margin-right: 5rem;
    }
  }

  &__title {
    font-family: Grotesk;
    text-transform: uppercase;
    font-size: 3rem;
    line-height: 4rem;
    margin-bottom: 3rem;
  }

  &__label {
    font-weight: 500;
    font-size: 2rem;
    display: flex;
    align-items: center;
    opacity: 0.5;
    transition: 0.1s ease opacity;

    svg {
      margin-left: 0.5rem;
      fill: $whitecolor;
    }
  }

  &__subtitle {
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 2rem;
    font-size: 2.4rem;
    line-height: 2.4rem;

    // &.blue {
    //   color: $bluecolor;
    // }
  }
}
