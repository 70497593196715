@import "../abstracts/variables.scss";

.inventory {
  position: relative;
  width: 100%;
  flex: 1 1 100%;

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  &__content {
    position: relative;
    z-index: 2;
    width: 100%;
    color: $whitecolor;
  }

  &__tabs {
    width: 100%;
    border-bottom: 0.1rem solid $darkpurplecolor;
    display: flex;
    align-items: center;
    margin-bottom: 3.3rem;
    padding-left: 1.4rem;
  }

  &__tab {
    font-family: "Grotesk";
    font-size: 2.1rem;
    line-height: 2.8rem;
    text-transform: uppercase;
    position: relative;
    padding: 3.6rem;
    padding-bottom: 2.6rem;
    color: $darkpurplecolor;
    transition: 0.1s ease color;
    cursor: pointer;

    &::after {
      content: "";
      opacity: 0;
      visibility: hidden;
      height: 0.3rem;
      width: 70%;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      background: $whitecolor;
      transition: 0.1s ease opacity, 0.1s ease visibility;
      position: absolute;
    }

    &:hover {
      color: #ccbac7;
    }

    &--active {
      color: $whitecolor !important;

      &::after {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__filters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 5rem;
    margin-bottom: 6rem;
    margin-right: 18rem;

    &--hidden {
      display: none;
    }
  }

  &__selects {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__rarity {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    gap: 1rem;
    grid-row-gap: 2rem;
    padding-left: 4rem;
    padding-right: 8.5rem;
    height: 100%;
    width: 99%;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 76vh;
    padding-bottom: 5rem;

    &--cars {
      grid-template-columns: repeat(7, 1fr);
      max-height: 83vh;
    }
  }

  &__grid_item {
    height: 30rem;

    &--empty {
      background: rgba(14, 6, 16, 0.5);
    }
  }

  &__no_items {
    height: 76vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    div {
      font-family: "Grotesk";
      font-size: 5rem;
      line-height: 6rem;
      margin-bottom: 2rem;
    }
  }
}
