@import "../abstracts/variables.scss";

.hint {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 11;
  align-items: center;
  justify-content: center;
  display: flex;
  pointer-events: none;

  $self: &;

  &--open {
    pointer-events: all;

    #{$self}__hole,
    #{$self}__content,
    #{$self}__img {
      opacity: 1;
    }

    #{$self}__hole {
      // transition-delay: 0.1s;
    }

    #{$self}__content {
      transition-delay: 0.5s;
    }

    #{$self}__img {
      transition-delay: 0.4s;
    }
  }

  &__content {
    position: relative;
    width: 82.2rem;
    height: 44.1rem;
    opacity: 0;
    transition: 0.2s ease opacity;
  }

  &__frame {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &__wrapper {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    padding: 6.1rem 9.3rem;
    padding-right: 11.5rem;
  }

  &__name {
    color: $blackcolor;
    text-transform: uppercase;
    font-size: 3.5rem;
    text-transform: uppercase;
    margin-bottom: 1.5rem;
  }

  &__text {
    color: $whitecolor;
    font-size: 2.5rem;
    line-height: 2.6rem;
    text-transform: uppercase;
    margin-bottom: 2rem;

    span {
      font-weight: 600;
    }
  }

  &__img {
    position: absolute;
    z-index: 3;
    bottom: 0;
    right: 7rem;
    width: 53.6rem;
    height: 74rem;
    opacity: 0;
    transition: 0.1s ease opacity;

    &--jason {
      width: 62.6rem;
      height: 67rem;
    }

    &--john {
      height: 86rem;
    }

    &--christmas {
      height: 83rem;
    }
  }

  &__button {
    color: $whitecolor;
    padding: 1rem 3.7rem;
    border-radius: 10rem;
    border: 0.2rem solid $whitecolor;
    font-size: 2rem;
    text-transform: uppercase;
  }

  &__button_row {
    display: flex;
    justify-content: flex-end;
  }

  &__hole {
    position: absolute;
    box-shadow: 0 0 0 9999px rgba(14, 6, 16, 0.9);
    border: 0.3rem solid $orangecolor;
    opacity: 0;
    transition: 0.3s ease opacity;
  }
}
