@font-face {
  font-family: "Grotesk";
  src: local("Grotesk"),
    url("../fonts/Grotesk-01Extrafin.otf") format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: "Grotesk";
  src: local("Grotesk"), url("../fonts/Grotesk-02Mince.otf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Grotesk";
  src: local("Grotesk"),
    url("../fonts/Grotesk-03Regular.otf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Grotesk";
  src: local("Grotesk"), url("../fonts/Grotesk-04Gras.otf") format("truetype");
  font-weight: 500;
}

// Including abstracts //
@import "abstracts/mixins.scss";
@import "abstracts/variables.scss";

// Including base styles //
@import "base/null.scss";
@import "base/main-styles.scss";

// Static styles
@import "vendors/react-toastify.scss";
