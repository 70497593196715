.car_avatar {
  position: relative;
  z-index: 2;
  top: 0;

  svg {
    height: 100%;
    width: 100%;
  }

  svg path {
    transition: 0.1s ease fill;
  }

  &__img {
    position: absolute;
    z-index: 3;
    top: 50%;
    transform: translateY(-50%);
  }
}
