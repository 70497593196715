.car_frames {
  position: relative;
  width: 75%;
  height: 23.5rem;
  margin-bottom: 1.5rem;
  margin-top: auto;
  z-index: 3;

  &__frame {
    position: absolute;
    width: 12.8rem;
    height: 11.8rem;
    overflow: hidden;

    svg {
      width: 100%;
      height: 100%;
    }

    &:first-child {
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &:nth-child(2) {
      top: -0.1rem;
      left: 50%;
      transform: translateX(-50%);
    }

    &:nth-child(3) {
      bottom: -0.1rem;
      left: 50%;
      transform: translateX(-50%);
    }

    &:last-child {
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }

  &__wrapper {
    position: relative;
    top: -50%;
    transform: translateY(-50%);
    width: 12.5rem;
    height: 9.9rem;
    left: 0.4rem;
    overflow: hidden;
  }
}
