@import "../abstracts/variables.scss";

.main {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 100%;

  &--street {
    flex-direction: column;
  }

  &__bg {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__grid {
    width: 100%;
    padding: 2rem 8rem;
    position: relative;
    z-index: 2;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
    row-gap: 0;

    @media (max-width: $md3) {
      padding: 0 8rem;
      max-width: 83%;
    }

    @media (max-width: $md4) {
      padding: 0 8rem;
    }

    &--small {
      grid-template-rows: 1fr 1fr;
    }

    &--free {
      grid-template-columns: 1fr 1fr;
      width: 69%;

      @media (max-width: $md3) {
        max-width: 56%;
      }
    }

    &--special {
      grid-template-columns: 1fr;
      max-width: 39%;

      @media (max-width: $md3) {
        max-width: 32%;
      }
    }

    &--scrollable {
      max-height: 78rem;
      overflow-y: auto;
      overflow-x: hidden;
      margin-right: 1rem;
      padding-left: 8.5rem;

      @media (max-width: $md2) {
        grid-template-columns: 1fr 1fr;
        max-height: 120rem;
        max-width: 90%;
      }

      @media (max-width: $md3) {
        max-height: unset;
        overflow-y: visible;
        max-width: 83%;
        padding-bottom: 5rem;
      }
    }
  }

  &__double_card {
    display: flex;
    flex-direction: column;
  }

  &__filters_wrapper {
    position: relative;
    z-index: 2;
    width: 100%;
    padding: 0 8rem;
    padding-top: 4rem;

    @media (max-width: $md2) {
      padding: 0 15rem;
      padding-top: 2rem;
    }

    @media (max-width: $md3) {
      padding: 0 24rem;
      padding-top: 2rem;
      margin-bottom: 2rem;
    }

    & > div {
      max-width: 44rem;
      margin-bottom: 0;
    }
  }

  &__filters {
    display: flex;
    align-items: center;
    top: 1rem;
    background: rgba($darkcolor, 0.9);
    padding: 1rem 2rem;
    padding-right: 1rem;
    right: 4.5%;
    z-index: 4;
    color: $whitecolor;
    border-radius: 1rem;

    @media (max-width: $md3) {
      top: 13rem;
      right: 1.5%;
    }

    span {
      display: flex;
      align-items: center;
      margin-right: 2rem;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 2rem;

      svg {
        margin-left: 0.5rem;
      }

      svg path {
        fill: $orangecolor;
      }
    }
  }
}
