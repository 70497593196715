@import "../abstracts/variables.scss";

.small_button {
  max-width: 20rem;
  width: 100%;
  position: relative;
  height: 3.8rem;
  z-index: 4;

  $self: &;

  &:not(:last-child) {
    margin-right: 3rem;
  }

  &:hover {
    span {
      background: transparent;
    }
  }

  &--disabled {
    cursor: default;

    &:hover {
      span {
        background: $darkcolor;
      }
    }

    #{$self}__wrapper {
      background: gray;
    }
  }

  &__wrapper {
    cursor: pointer;
    border-radius: 2rem;
    text-transform: uppercase;
    padding: 0.2rem;
    background-image: linear-gradient(180deg, #ff7854 0%, #e73b4b 100%);
    font-size: 1.6rem;
    color: $whitecolor;
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    span {
      transition: 0.1s ease background;
      background: $darkcolor;
      border-radius: 10rem;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      padding: 0.7rem 2rem;
      white-space: nowrap;
    }
  }
}
