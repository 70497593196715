@import "../abstracts/variables.scss";

.go_back {
  position: absolute;
  top: 1rem;
  left: 1rem;
  cursor: pointer;
  z-index: 5;

  @media (max-width: $md3) {
    height: 6rem;
    width: 6rem;
  }
}
