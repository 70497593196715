@import "../../abstracts/variables.scss";

.outlined {
  background: $darkcolor;
  border: 0.2rem solid $orangecolor;
  border-radius: 2rem;
  padding: 4rem 2.5rem;
  color: $whitecolor;
  max-width: 80rem;

  &__content {
    max-height: 65rem;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__title {
    text-transform: uppercase;
    font-size: 3.4rem;
    margin-bottom: 5rem;
    text-align: center;
    padding-top: 1rem;
  }

  &__text {
    font-size: 2.4rem;
    padding-right: 5rem;
    line-height: 3rem;

    img {
      margin-top: 2rem;
      margin-bottom: 4rem;
    }

    p {
      margin-bottom: 2rem;
    }

    a {
      text-decoration: underline;
      font-weight: 800;
    }
  }
}
