.counter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 10rem;

  &__plus,
  &__minus {
    cursor: pointer;
    transition: 0.1s ease transform;

    &:hover {
      transform: scale(1.05);
    }

    &:active {
      transform: scale(0.95);
    }

    svg {
      height: 5.4rem;
      width: 5.4rem;
    }

    &--disabled {
      pointer-events: none;

      &:hover,
      &:active {
        transform: scale(1);
      }

      svg {
        path {
          stroke: gray;
        }

        cursor: default;
      }
    }
  }

  &__plus {
    margin-left: 2rem;
  }

  &__minus {
    margin-right: 2rem;
  }

  &__border {
    height: 12rem;
    width: 12rem;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 4rem;
    text-transform: uppercase;
    font-family: "Grotesk";
    background-color: transparent;

    svg {
      position: absolute;
      top: 0;
      left: 0;
      height: 12rem;
      width: 12rem;
      transition: transform 7s linear;
      animation-duration: 7s;
      animation-fill-mode: both;
      animation-iteration-count: infinite;
      animation-name: iconAnimation;
      transform: rotate3d(0, 1, 0, -25deg) translateZ(1px);
    }
  }

  &__value {
    user-select: none;
  }
}

@keyframes iconAnimation {
  0% {
    transform: rotate3d(0, 1, 0, -25deg), translateZ(1px);
  }

  50% {
    transform: rotate3d(0, 1, 0, 25deg), translateZ(1px);
  }

  100% {
    transform: rotate3d(0, 1, 0, -25deg), translateZ(1px);
  }
}
