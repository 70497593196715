@import "../../styles/abstracts/variables.scss";

.title {
  text-align: center;
  position: relative;
  margin-bottom: 15rem;
  z-index: 2;

  @media (max-width: $md1) {
    margin-bottom: 10rem;
  }

  @media (max-width: $md2) {
    margin-bottom: 6rem;
  }

  &__main {
    font-family: Hacked;
    font-size: 4.8rem;
    line-height: 5.522rem;
    position: relative;
    z-index: 2;

    @media (max-width: $md3) {
      font-size: 3.2rem;
      line-height: 3.6rem;
    }
  }

  &__secondary {
    color: transparent;
    font-size: 11rem;
    line-height: 14.5rem;
    position: absolute;
    bottom: -9rem;
    font-weight: 700;
    text-transform: uppercase;
    -webkit-text-stroke: 0.1rem $darktextcolor;
    width: 100%;
    white-space: nowrap;
    text-align: center;

    @media (max-width: $md1) {
      font-size: 7rem;
    }

    @media (max-width: $md2) {
      font-size: 6rem;
      line-height: 8rem;
      bottom: -3rem;
    }

    @media (max-width: $md3) {
      font-size: 3rem;
      line-height: 4.5rem;
      bottom: -2rem;
    }
  }
}
