@import "../../../styles/abstracts/variables.scss";

.brakes {
  position: relative;

  &__separator {
    position: absolute;
    bottom: 0;
    width: 100%;

    @media (max-width: $md3) {
      bottom: -1rem;
    }
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__content {
    padding-top: 7.5rem;
    padding-bottom: 35rem;

    @media (max-width: $md2) {
      padding-top: 3rem;
      padding-bottom: 20rem;
    }

    @media (max-width: $md3) {
      padding-top: 7rem;
      padding-bottom: 13rem;
    }
  }

  &__row {
    display: flex;
    position: relative;
    z-index: 2;
    max-width: 61rem;
    margin: 0 auto;
    align-items: center;

    @media (max-width: $md2) {
      flex-direction: column;
      max-width: 33.5rem;
    }
  }

  &__button {
    max-width: 21rem;
    margin-left: 2rem;

    @media (max-width: $md2) {
      max-width: unset;
      margin-left: 0;
      margin-top: 2rem;
    }
  }
}
