@import "../../abstracts/variables.scss";

.confirm {
  background: $darkcolor;
  box-shadow: 0px 0px 30px 0px rgba(41, 242, 217, 0.8);
  padding: 3rem 10rem;
  padding-bottom: 5rem;
  color: $whitecolor;
  border-radius: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 65.5rem;
  width: 100%;

  &__title,
  &__subtitle {
    text-transform: uppercase;
    font-size: 2.6rem;
    line-height: 2.8rem;
    font-family: "Grotesk";
    text-align: center;
  }

  &__warning {
    color: $orangecolor;
    text-transform: uppercase;
    margin-bottom: 5rem;
    font-size: 1.6rem;
  }

  &__title {
    margin-bottom: 5.5rem;
  }

  &__subtitle {
    margin-bottom: 7rem;
  }

  &__amount_row {
    position: relative;
    margin-bottom: 7rem;
    color: $darkpurplecolor;
    font-size: 2.4rem;
    line-height: 2.8rem;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    width: 100%;

    &::before {
      content: "";
      position: absolute;
      bottom: 0.3rem;
      left: 50%;
      transform: translateX(-50%);
      height: 0.1rem;
      background: $darkpurplecolor;
      width: 58%;
    }

    span {
      color: $whitecolor;
    }

    &--fee {
      margin-bottom: 0.5rem;

      &::before {
        width: 23%;
      }
    }

    &--less-margin {
      margin-bottom: 2rem;
    }
  }

  &__button {
  }
}
