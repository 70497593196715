@import "../abstracts/variables.scss";

.custom_scrollbar {
  &::-webkit-scrollbar {
    width: 0.7rem;
  }

  &::-webkit-scrollbar-track {
    background: $graycolor;
    border-radius: 1rem;
    margin-bottom: 1rem;
  }

  &::-webkit-scrollbar-thumb {
    background: $orangecolor;
    border-radius: 1rem;
  }
}

.invisible_scrollbar {
  &::-webkit-scrollbar {
    width: 0;
  }

  &::-webkit-scrollbar-track {
    background: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: 0;
  }
}
