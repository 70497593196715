@import "../../abstracts/variables.scss";

.pin_input {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5rem;

  &__field {
    transition: 0.1s ease border, 0.1s ease color;
    border-radius: 0.4rem;
    background: $darkgraycolor;
    color: $whitecolor;
    border: 0.1rem solid $darkgraycolor;
    font-size: 2rem;
    height: 6rem;
    width: 6rem;
    padding: 1rem 2rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    &:not(:last-child) {
      margin-right: 1rem;
    }

    &:hover {
      border: 0.1rem solid #726b73;
    }

    &--filled {
      border: 0.1rem solid $whitecolor;
    }

    &:focus {
      border: 0.1rem solid $orangecolor;
      color: $orangecolor;
    }
  }
}
