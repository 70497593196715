@import "../../abstracts/variables.scss";

.input {
  font-size: 1.8rem;
  color: $whitecolor;
  padding: 1.5rem 2.5rem;
  border-radius: 0.4rem;
  background: $darkgraycolor;
  border: 0.1rem solid $darkgraycolor;
  width: 100%;
  height: 5rem;
  position: relative;

  @media (max-width: $md3) {
    font-size: 2.4rem;
    padding: 2rem 3rem;
    height: 6rem;
  }

  &:hover {
    border: 0.1rem solid #726b73;
  }

  &--filled,
  &:focus {
    border: 0.1rem solid $whitecolor;
  }

  &--password {
    padding-right: 6rem;
  }

  &__show_icon {
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 2rem;
    transform: translateY(calc(-50% - 0.1rem));
    height: 1.5rem;

    &--label {
      top: 68%;
    }
  }
}
