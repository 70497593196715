.total_points {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 6rem;
  height: 5rem;
  position: relative;

  $self: &;

  &--small {
    width: 4.5rem;
    height: 3.8rem;

    #{$self}__icon svg {
      width: 4.5rem !important;
      height: 3.8rem !important;
    }
  }

  &__icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__number {
    font-size: 2rem;
    text-transform: uppercase;
  }
}
